import React, { useEffect, useRef, useState } from 'react';
import { DetailBox, PopUpBox, Scanner, Table, TitleBar } from '../../common';
import {
  BUTTON,
  DELIVERY_OPERATIONS_COLUMN,
  HEADING,
  POPUP_TYPE,
  ROUTES,
  TITLES,
  TRANSFER_DETAIL_COLUMN,
} from '../../../constants';
import {
  innerTransferDetailedOperationCompiler,
  innerTransferOperationsCompiler,
  transferDetailBox,
} from '../../../utils/data_compiler/transferCompiler';
import { Button } from '../../shared';
import { ScanIcon } from '../../../assets/icons';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import {
  finalizePick,
  printPackingListDelivery,
  printUpsLabelDelivery,
  scanLocationkWT,
  warehouseCheckUncheck,
  warehousePackageScan,
  warehouseTransfer,
  warehouseTransferQtyUpdate,
  warehouseValidatePicking,
} from '../../../service';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { popup } from '../../../utils';

export const TransfersDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation(location?.state);
  const [reload, setReload] = useState(false);
  const [stateData] = useState(state);
  const [transfersData, setTransfersData] = useState(stateData);
  const [data, setData] = useState(innerTransferDetailedOperationCompiler(transfersData));
  const [activeTab, setActiveTab] = useState('operations');
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [scanDestinationLocationData, setScanDestinationLocationData] = useState(false);
  const [showPopup, setShowPopup] = useState(0);
  const [popUpData, setPopUpData] = useState('');
  const disabledStates = ['draft', 'waiting', 'done', 'cancel', 'confirmed'];
  const columnsToFilterOut = [];
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  let buttonsDisabled = disabledStates.includes(transfersData?.state);
  let filteredColumns;

  if (buttonsDisabled) {
    columnsToFilterOut.push('Action', 'Status');
    if (transfersData?.state === 'draft' || transfersData?.state === 'waiting')
      columnsToFilterOut.push('Done Quantity');
    filteredColumns = TRANSFER_DETAIL_COLUMN.filter(
      (col) => !columnsToFilterOut.includes(col.name),
    );
  } else if (transfersData?.sale_id === false) {
    columnsToFilterOut.push('Action');
    filteredColumns = TRANSFER_DETAIL_COLUMN.filter(
      (col) => !columnsToFilterOut.includes(col.name),
    );
  } else {
    filteredColumns = TRANSFER_DETAIL_COLUMN;
  }

  const hideScan = () => {
    return (
      data.every((obj) => obj?.blpName === 'N/A') || transfersData?.picking_type === 'loose_pieces'
    );
  };

  const hideCreateTransfer = () => {
    return data.every((obj) => obj?.blpName !== 'N/A');
  };

  const enableScan = () => {
    for (let obj of data) {
      if (obj?.blpName !== 'N/A' && obj?.scan_picked === false) return true;
    }
    return false;
  };

  const enableConfirm = () => {
    for (let obj of data) {
      if (!obj?.checked) return false;
      if (transfersData?.picking_type !== 'loose_pieces')
        if (obj?.blpName !== 'N/A' && obj?.scan_picked === false) return false;
    }
    return true;
  };

  const startScanning = () => {
    setScanning((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    isCameraScanBool && !isCamScannerOpen ? setScanning(false) : '';
  });

  useEffect(() => {
    if (!enableScan() && scanning) {
      setScanning(false);
    }
  }, [data, scanning]);

  const startScanningDestination = () => {
    setScanDestinationLocationData((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      if (scanning) {
        scanPackageAPI(scanData, true);
      }
      if (scanDestinationLocationData) {
        handleDestinationLocationScan(scanData);
      }
      setScanData('');
      setScanDestinationLocationData(false);
    }
  }, [scanData, scanDestinationLocationData]);

  const scanPackageAPI = useDebounce((scanData, scanType) => {
    dispatch(setLoaderVisibility(true));
    warehousePackageScan({
      data: {
        package_name: scanData,
        id: transfersData.id,
        model: transfersData?.model,
        scan_type: scanType ? 'add' : 'remove',
      },
    })
      .then((response) => {
        if (response.message.includes('not') || response.message.includes('more'))
          popup(POPUP_TYPE.ERR, response.message);
        else
          popup(
            POPUP_TYPE.SUCCESS,
            scanType ? response.message : `Package ${scanData} unscanned successfully`,
          );
        dispatch(setLoaderVisibility(false));
        getTransfersList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const updateQtyAPI = useDebounce((done_qty, move_line_id) => {
    dispatch(setLoaderVisibility(true));
    warehouseTransferQtyUpdate({
      data: {
        move_line_id: move_line_id,
        done_qty: parseFloat(done_qty),
      },
    })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        dispatch(setLoaderVisibility(false));
        getTransfersList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const getTransfersList = useDebounce(() => {
    warehouseTransfer({
      data: { id: transfersData.id },
    })
      .then((response) => {
        setReload(false);
        dispatch(setLoaderVisibility(false));
        setTransfersData(response.data[0]);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleFinalizePick = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    finalizePick({
      data: { id: transfersData?.id, model: transfersData?.model },
    })
      .then((response) => {
        setReload(true);
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handlePrintUpsLabel = async () => {
    dispatch(setLoaderVisibility(true));
    printUpsLabelDelivery({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          window.open(response.data[0].split('?')[0], '_blank', 'noreferrer');
          getTransfersList();
        } else {
          popup(POPUP_TYPE.ERR, response.message);
        }
      })
      .catch((err) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, err.message);
        throw err;
      });
  };

  const handlePrintPackingList = async () => {
    dispatch(setLoaderVisibility(true));
    printPackingListDelivery({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          window.open(response.data[0].split('?')[0], '_blank', 'noreferrer');
        } else {
          popup(POPUP_TYPE.ERR, response.message);
        }
      })
      .catch((err) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, err.message);
        throw err;
      });
  };

  const handleDestinationLocationScan = useDebounce((scanData) => {
    dispatch(setLoaderVisibility(true));
    scanLocationkWT({
      data: { picking_id: transfersData.id, barcode: scanData },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const validateOrder = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    warehouseValidatePicking({
      data: { picking_id: transfersData.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.TRANSFERS);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getTransfersList();
  }, []);

  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getTransfersList();
    }
  }, [reload]);

  useEffect(() => {
    setData(innerTransferDetailedOperationCompiler(transfersData));
  }, [transfersData]);
  const handleCreatePackage = (e) => {
    e.stopPropagation();
    navigate(ROUTES.CREATE_TRANSFER, { state: state });
  };

  useEffect(() => {
    if (
      (transfersData?.state === 'assigned' ||
        (transfersData?.state === 'in_progress' &&
          transfersData?.model === 'stock.picking.batch')) &&
      !hideScan() &&
      enableScan()
    ) {
      const handlePaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text').trim();
        if (pastedData) {
          scanPackageAPI(pastedData, true);
        }
      };
      window.addEventListener('paste', handlePaste);
      return () => {
        window.removeEventListener('paste', handlePaste);
      };
    }
  }, [scanPackageAPI]);

  useEffect(() => {
    if (!state) navigate(ROUTES.TRANSFERS);
  }, [state]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.TRANSFER, route: ROUTES.TRANSFERS },
          { text: transfersData?.name, route: ROUTES.TRANSFER_DETAIL },
        ]}
      />
      <div className="scroll-div">
        <div className="d-flex align-items-center justify-content-end">
          {scanDestinationLocationData && (
            <Scanner
              setScanData={setScanData}
              isCamScannerOpen={isCamScannerOpen}
              setIsCamScannerOpen={setIsCamScannerOpen}
            />
          )}
          {transfersData?.state === 'assigned' && transfersData?.finalize_picked && (
            <Button
              className={`white-btn d-flex align-items-center ${scanning ? 'disabled' : ''} justify-content-center px-4 mb-3 mx-2`}
              clickHandler={startScanningDestination}
              disabled={scanning}>
              {scanDestinationLocationData ? BUTTON.STOP_SCAN : BUTTON.SCAN_DESTINATION_LOCATION}
            </Button>
          )}
          {transfersData?.sale_id &&
            (transfersData?.state === 'assigned' || transfersData?.state === 'in_progress') &&
            !transfersData?.finalize_picked && (
              <Button
                clickHandler={handleFinalizePick}
                className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                {BUTTON.FINALIZE_PICK}
              </Button>
            )}
          {transfersData?.picking_type === false && (
            <div className="d-flex align-items-center justify-content-end">
              {(transfersData?.state === 'done' || transfersData?.state === 'assigned') && (
                <Button
                  clickHandler={handlePrintPackingList}
                  className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                  {BUTTON.PRINT_PACKING_LIST}
                </Button>
              )}
              {transfersData?.carrier_id?.delivery_type === 'ups' &&
                transfersData?.generate_ups_label &&
                transfersData?.carrier_tracking_ref === false && (
                  <Button
                    clickHandler={handlePrintUpsLabel}
                    className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                    {BUTTON.PRINT_UPS_LABEL}
                  </Button>
                )}
            </div>
          )}
          {(transfersData?.state === 'assigned' ||
            (transfersData?.state === 'in_progress' &&
              transfersData?.model === 'stock.picking.batch')) && (
            <Button
              clickHandler={validateOrder}
              className={`blue-btn d-flex ${enableConfirm() ? '' : 'disabled'} ${transfersData?.finalize_picked ? '' : 'disabled'} align-items-center justify-content-center px-4 mx-2 mb-3`}
              disabled={!enableConfirm() || !transfersData?.finalize_picked}>
              {BUTTON.ConfirmTransferOrder}
            </Button>
          )}
        </div>
        <DetailBox colCount={2} colData={transfersData} dataCompiler={transferDetailBox} />

        {/* Tab Navigation */}
        <div className="d-flex align-items-center tab-nav bg-white">
          <div
            className={`text-center w-50 flex-fill ${activeTab === 'operations' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('operations')}>
            <h4>{HEADING.OPERATIONS}</h4>
          </div>
          <div
            className={`text-center w-50 flex-fill ${activeTab === 'detailOperation' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('detailOperation')}>
            <h4>{HEADING.DETAIL_OPERATIONS}</h4>
          </div>
        </div>

        {/* Tab Content */}
        {activeTab === 'operations' && (
          <div className="tab-content">
            <div className="my-3">
              <Table
                columns={DELIVERY_OPERATIONS_COLUMN}
                initialData={stateData?.move_ids}
                PAGE_SIZE={5}
                colFilter={false}
                compiler={{ use: true, tool: innerTransferOperationsCompiler }}
              />
            </div>
          </div>
        )}

        {activeTab === 'detailOperation' && (
          <div className="tab-content">
            <div className="d-flex justify-content-end">
              {scanning && (
                <Scanner
                  setScanData={setScanData}
                  isCamScannerOpen={isCamScannerOpen}
                  setIsCamScannerOpen={setIsCamScannerOpen}
                />
              )}
              {(transfersData?.state === 'assigned' ||
                (transfersData?.state === 'in_progress' &&
                  transfersData?.model === 'stock.picking.batch')) &&
                !hideScan() && (
                  <Button
                    className={`white-btn ${enableScan() ? '' : 'disabled'} d-flex align-items-center justify-content-center px-4 mx-2`}
                    clickHandler={startScanning}
                    disabled={!enableScan()}>
                    {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
                  </Button>
                )}
              {transfersData?.state === 'assigned' &&
                !hideCreateTransfer() &&
                transfersData?.create_delivery_package && (
                  <Button
                    clickHandler={(e) => handleCreatePackage(e)}
                    className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2`}>
                    {BUTTON.CREATE_PACKAGE}
                  </Button>
                )}
            </div>
            <div className="my-3">
              <Table
                columns={filteredColumns.filter((col) => !columnsToFilterOut.includes(col.name))}
                initialData={data}
                PAGE_SIZE={5}
                to={null}
                compiler={{ use: false }}
                popup={showPopup}
                setPopUp={setShowPopup}
                setPopUpData={setPopUpData}
                setReload={setReload}
              />
              {showPopup === 1 && (
                <PopUpBox openPopUpBox={showPopup}>
                  <QtyUpdate
                    apiCall={updateQtyAPI}
                    setOpenConfirmationBox={setShowPopup}
                    data={popUpData}
                  />
                </PopUpBox>
              )}
              {showPopup === 2 && (
                <PopUpBox openPopUpBox={showPopup}>
                  <RemoveScan
                    apiCall={scanPackageAPI}
                    pickingId={transfersData.id}
                    data={popUpData}
                    setOpenConfirmationBox={setShowPopup}
                  />
                </PopUpBox>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const QtyUpdate = ({ setOpenConfirmationBox, apiCall, data }) => {
  const [doneQty, setDoneQty] = useState('');
  const qtyRef = useRef();
  const closePopUp = () => {
    setOpenConfirmationBox(0);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(0);
    apiCall(doneQty, data.move_line_id);
  };

  useEffect(() => {
    qtyRef.current.focus();
  }, []);
  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h4 className=" text-center text-special mb-3 "> Enter Qty. </h4>
        <div className="w-100 modal-body py-0 d-flex flex-column  gap-3 justify-content-center align-items-center">
          <div className="d-flex justify-content-between w-100 ">
            <label className="ms-3">Quantity</label>
            <input
              type="number"
              className="ps-2 curve-border me-4"
              placeholder={data.reserve_quantity}
              ref={qtyRef}
              onChange={(e) => {
                setDoneQty(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

const RemoveScan = ({ apiCall, setOpenConfirmationBox, data }) => {
  const closePopUp = () => {
    setOpenConfirmationBox(0);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(0);
    apiCall(data?.blpName, !data?.scan_picked);
  };
  return (
    <>
      <div className="d-flex m-1 ">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className=" text-center text-special mb-3 ">Un-Scan</h4>
          <p className="text-center text-special placeholder-color ">
            Are you sure to remove scan?
          </p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CONFIRM}
          </Button>{' '}
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

export const CheckUncheckTransfer = (data) => {
  const dispatch = useDispatch();
  const handleCheckUncheck = () => {
    dispatch(setLoaderVisibility(true));
    warehouseCheckUncheck({
      data: {
        move_line_ids: [data.move_line_id],
        status: data?.checked ? false : true,
      },
    })
      .then(() => {
        popup(POPUP_TYPE.SUCCESS, data?.checked ? 'Status Unchecked' : 'Status Checked');
        data.setReload(true);
        dispatch(setLoaderVisibility(false));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const handleCheck = () => {
    handleCheckUncheck();
  };
  return (
    <input type="checkbox" className="checkbox" checked={data?.checked} onClick={handleCheck} />
  );
};
