import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BUTTON, POPUP_TYPE, STOCK_LOOKUP_COLUMNS, TITLES } from '../../../constants';
import { SearchBarStockLookup, PaginatedTable, TitleBar, DropDown } from '../../common';
import { setLoaderVisibility } from '../../../redux';
import { Button } from '../../shared';
import { warehouseStockLookup } from '../../../service';
import { useDebounce } from '../../../hooks';
import { popup } from '../../../utils';
import { stockLookupDataCompiler } from '../../../utils/data_compiler/stockLookupDataCompiler';

const barcodeMapping = {
  'SHO/Permanent': 'SHO/Stock/Permanent',
  'SHO/Open': 'SHO/Stock/Open',
  'SKW/Stock': 'SKW-STOCK',
};

export const StockLookupContent = () => {
  const dispatch = useDispatch();
  const [locationBarcode, setLocationBarcode] = useState('');
  const [productSKU, setProductSKU] = useState('');
  const [packageId, setPackageId] = useState('');
  const [prevSearch, setPrevSearch] = useState({
    locationBarcode: '',
    productSKU: '',
    packageId: '',
  });
  const [search, setSearch] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [isClearDisabled, setIsClearDisabled] = useState(true);
  const [sort1, setSort1] = useState('Select Location');
  const sortingArray1 = Object.keys(barcodeMapping);

  const fetchStockData = useDebounce((params = {}) => {
    dispatch(setLoaderVisibility(true));
    warehouseStockLookup({ data: params })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.records?.length > 0) {
          setStockData(response.data.records);
          setPaginationData(response.data.params);
        } else {
          setStockData([]);
          popup(POPUP_TYPE.ERR, response?.message);
        }
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
      });
  }, 300);

  const getStockData = (additionalParams = {}) => {
    const params = {
      location_barcode: locationBarcode || undefined,
      product_sku: productSKU || undefined,
      package_id: packageId || undefined,
      groupby: barcodeMapping[sort1] || undefined,
      page_number: pageNumber,
      ...additionalParams,
    };
    fetchStockData(params);
  };

  useEffect(() => {
    if (search) {
      setPageNumber(1);
      getStockData({ page_number: 1 });
      setPrevSearch({ locationBarcode, productSKU, packageId });
      setSearch(false);
      setIsSearchDisabled(true);
    }
  }, [search]);

  useEffect(() => {
    if (locationBarcode || productSKU || packageId) {
      dispatch(setLoaderVisibility(true));
      getStockData();
    }
  }, [pageNumber]);

  useEffect(() => {
    const hasInput = locationBarcode || productSKU || packageId;
    const isSameSearch =
      locationBarcode === prevSearch.locationBarcode &&
      productSKU === prevSearch.productSKU &&
      packageId === prevSearch.packageId;
    setIsSearchDisabled(!hasInput || isSameSearch);
    setIsClearDisabled(!hasInput);
  }, [locationBarcode, productSKU, packageId]);

  const handleSearch = () => {
    if (!isSearchDisabled) {
      setSearch(true);
    }
  };

  const handleClearFilter = () => {
    setStockData([]);
    setLocationBarcode('');
    setProductSKU('');
    setPackageId('');
    setPageNumber(1);
    setPrevSearch({ locationBarcode: '', productSKU: '', packageId: '' });
    setIsSearchDisabled(true);
    setIsClearDisabled(true);
    setSort1('Select Location');
  };

  const handleDropdownChange = (value) => {
    setSort1(value);
    setSearch(true);
  };

  const handleClearDropdown = () => {
    setSort1('Select Location');
    if (productSKU !== '' || locationBarcode !== '' || packageId !== '') setSearch(true);
    else if (productSKU === '' && locationBarcode === '' && packageId === '') setStockData([]);
  };

  return (
    <>
      <TitleBar title={[{ text: TITLES.STOCK_LOOKUP }]} />
      <div className="d-flex justify-content-start gap-2">
        <SearchBarStockLookup
          setSearchTerm={setLocationBarcode}
          searchTerm={locationBarcode}
          placeholder={'Location Barcode'}
        />
        <SearchBarStockLookup
          setSearchTerm={setProductSKU}
          searchTerm={productSKU}
          placeholder={'Product SKU'}
        />
        <SearchBarStockLookup
          setSearchTerm={setPackageId}
          searchTerm={packageId}
          placeholder={'Package Id'}
        />
        <Button
          clickHandler={handleSearch}
          className={`search-btn px-3 ${isSearchDisabled ? 'disabled' : ''}`}
          disabled={isSearchDisabled}>
          {BUTTON.SEARCH}
        </Button>
        <Button
          clickHandler={handleClearFilter}
          className={`search-btn px-3 ${isClearDisabled ? 'disabled' : ''}`}
          disabled={isClearDisabled}>
          {BUTTON.CLEAR_FILTERS}
        </Button>
      </div>
      <div className="w-50 d-flex align-items-center justify-content-start mb-1">
        <DropDown
          dropdownMenu={sortingArray1}
          value={sort1}
          setValue={handleDropdownChange}
          capitalize={true}
        />
        {sortingArray1.includes(sort1) && (
          <Button clickHandler={handleClearDropdown} className="blue-btn px-3">
            Clear Filter
          </Button>
        )}
      </div>
      {stockData.length ? (
        <PaginatedTable
          columns={STOCK_LOOKUP_COLUMNS}
          initialData={stockData}
          colFilter={true}
          compiler={{ use: true, tool: stockLookupDataCompiler }}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          paginationData={paginationData}
        />
      ) : (
        <div>
          <h4 className="text-center py-5">No data available, please search..!!</h4>
        </div>
      )}
    </>
  );
};
