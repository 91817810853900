import React, { useEffect, useRef, useState } from 'react';
import { DetailBox, InputWithSearch, PopUpBox, Scanner, Table, TitleBar } from '../../common';
import {
  BUTTON,
  HEADING,
  POPUP_MSG,
  POPUP_TYPE,
  ROUTES,
  TITLES,
  WT_OPERATIONS_COLUMN,
  WT_OPERATIONS_COLUMN_DESTINATION_SET,
  WT_TRANSFER_DETAIL_COLUMN,
  WT_TRANSFER_DETAIL_COLUMN_DESTINATION_SET,
} from '../../../constants';
import {
  innerWtOperationsCompiler,
  wtDetailBox,
  wtInnerDetailedOperationCompiler,
} from '../../../utils/data_compiler/transferCompiler';
import { Button } from '../../shared';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import {
  cancelWarehouseTransfer,
  createTransferWT,
  immediateTransferWT,
  immediateUnpackWT,
  loadStockWT,
  markAsTodoWT,
  packageScanLoadStockWT,
  packageScanWT,
  removeStockWT,
  scanLocationkWT,
  updatePhysicallyAvailableQuantityWT,
  warehouseTransfer,
  warehouseValidatePicking,
  warehouseWholeStockTransfer,
} from '../../../service';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocalStorageItem, popup } from '../../../utils';
import { ScanIcon } from '../../../assets/icons';
import scan_duplicate from '../../../assets/sounds/duplicate.mp3';
import scan_failed from '../../../assets/sounds/error.mp3';

export const WHTDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation(location?.state);
  const [popUp, setPopUp] = useState(false);
  const [reload, setReload] = useState(false);
  const [transfersData, setTransfersData] = useState([]);
  const [data, setData] = useState(wtInnerDetailedOperationCompiler(transfersData));
  const [opsData, setOpsData] = useState(innerWtOperationsCompiler(transfersData));
  const [activeTab, setActiveTab] = useState('operations');
  const [packageData, setPackageData] = useState([]);
  const [clickedMove, setClickedMove] = useState();
  const [popUpData, setPopUpData] = useState('');
  const [scanData, setScanData] = useState('');
  const [scanningLoadStockPackages, setScanningLoadStockPackages] = useState(false);
  const [scanningPackages, setScanningPackages] = useState(false);
  const [scanSourceLocationData, setScanSourceLocationData] = useState(false);
  const [scanDestinationLocationData, setScanDestinationLocationData] = useState(false);
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const [pageNumber] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsOps, setSelectedItemsOps] = useState([]);
  let successAudio = new Audio(scan_duplicate);
  let failedAudio = new Audio(scan_failed);

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  const startScanningSource = () => {
    setScanSourceLocationData((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  const startScanningDestination = () => {
    setScanDestinationLocationData((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  const startScanningLoadStock = () => {
    setScanningLoadStockPackages((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  const startScanningPackages = () => {
    setScanningPackages((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    if (scanData) {
      // successAudio.play();
      dispatch(setLoaderVisibility(true));
      if (scanSourceLocationData) {
        successAudio.play();
        handleSourceLocationScan(scanData);
        setScanSourceLocationData(false);
      }
      if (scanDestinationLocationData) {
        successAudio.play();
        handleDestinationLocationScan(scanData);
        setScanDestinationLocationData(false);
      }
      if (scanningLoadStockPackages) {
        handlePackageScanLoadStock(scanData);
      }
      if (scanningPackages) {
        handlePackageScan(scanData);
      }
      setScanData('');
    }
  }, [
    scanData,
    scanningPackages,
    scanningLoadStockPackages,
    scanSourceLocationData,
    scanDestinationLocationData,
  ]);

  const getTransfersList = useDebounce(() => {
    warehouseTransfer({
      data: { picking_ids: state?.id, page_number: pageNumber },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (reload) setReload(false);
        setTransfersData(response?.data?.records?.[0]);
        setOpsData(innerWtOperationsCompiler(response?.data?.records?.[0]));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handleSourceLocationScan = useDebounce((scanData) => {
    scanLocationkWT({
      data: { picking_id: transfersData?.id, barcode: scanData, source: true },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handleDestinationLocationScan = useDebounce((scanData) => {
    scanLocationkWT({
      data: { picking_id: transfersData?.id, barcode: scanData },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handleLoadStock = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    setScanningPackages(false);
    setScanSourceLocationData(false);
    setScanDestinationLocationData(false);
    loadStockWT({
      data: { picking_id: transfersData?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        setTransfersData(response?.data?.[0]);
        setOpsData(innerWtOperationsCompiler(response?.data?.[0]));
        // setPaginationData(response?.data?.params)
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handleRemoveStock = useDebounce(() => {
    setScanningLoadStockPackages(false);
    dispatch(setLoaderVisibility(true));
    removeStockWT({
      data: { picking_id: transfersData?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        // getTransfersList();
        setTransfersData(response?.data?.[0]);
        setOpsData(innerWtOperationsCompiler(response?.data?.[0]));
        // setPaginationData(response?.data?.params)
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handleImmediateTransfer = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    immediateTransferWT({
      data: { picking_ids: transfersData?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handleImmediateUnpack = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    immediateUnpackWT({
      data: { picking_ids: transfersData?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handleMarkAsTodo = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    markAsTodoWT({
      data: { picking_id: transfersData?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.message.includes('[E101]'))
          popup(POPUP_TYPE.SUCCESS, response.message.split('-')[1]);
        else popup(POPUP_TYPE.SUCCESS, response.message);
        getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handleValidate = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    warehouseValidatePicking({
      data: { picking_id: transfersData?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.TRANSFERS);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handleWholeStockTransfer = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    warehouseWholeStockTransfer({
      data: { picking_id: transfersData?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.TRANSFERS);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
      });
  }, 400);

  const handleCancelTransfer = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    cancelWarehouseTransfer({ data: { picking_id: transfersData?.id } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.TRANSFERS);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handlePhysicallyAvailableQuantityUpdate = useDebounce((data) => {
    dispatch(setLoaderVisibility(true));
    updatePhysicallyAvailableQuantityWT({
      data: { ...data },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handlePackageScanLoadStock = useDebounce((scanData) => {
    const result = packageData.find((item) => item?.blp === scanData);
    if (result) {
      packageScanLoadStockWT({
        data: { package_level_id: result?.package_id, physically_available: true },
      })
        .then((response) => {
          dispatch(setLoaderVisibility(false));
          successAudio.play();
          popup(POPUP_TYPE.SUCCESS, response.message);
          setOpsData(innerWtOperationsCompiler(response?.data?.records?.[0]));
          // getTransfersList();
        })
        .catch(() => {
          dispatch(setLoaderVisibility(false));
          failedAudio.play();
          // popup(POPUP_TYPE.ERR, error.message);
        });
    } else {
      popup(POPUP_TYPE.ERR, 'Invalid Package Scanned');
      dispatch(setLoaderVisibility(false));
    }
  }, 400);

  const handleCreateTransfer = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    createTransferWT({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length) {
          popup(POPUP_TYPE.SUCCESS, response.message);
          setTransfersData(response.data[0]);
          setOpsData(innerWtOperationsCompiler(response.data[0]));
        } else popup(POPUP_TYPE.ERR, response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handlePackageScan = useDebounce((scanData) => {
    packageScanWT({
      data: { internal_transfer_id: transfersData?.id, package_name: [scanData], type: 'add' },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        successAudio.play();
        popup(POPUP_TYPE.SUCCESS, response.message);
        getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        failedAudio.play();
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  useEffect(() => {
    if (reload) getTransfersList();
  }, [reload]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getTransfersList();
  }, [pageNumber]);

  useEffect(() => {
    if (transfersData) setData(wtInnerDetailedOperationCompiler(transfersData));
    if (transfersData && transfersData?.package_level_ids)
      setPackageData(innerWtOperationsCompiler(transfersData));
  }, [transfersData]);

  useEffect(() => {
    if (!state) navigate(ROUTES.TRANSFERS);
  }, [state]);

  useEffect(() => {
    if (isCameraScanBool && !isCamScannerOpen) {
      setScanningPackages(false);
      setScanSourceLocationData(false);
      setScanDestinationLocationData(false);
      setScanningLoadStockPackages(false);
    }
  });

  useEffect(() => {
    if (
      transfersData?.state === 'draft' &&
      transfersData?.stock_loaded &&
      getLocalStorageItem('allow_cp') === true &&
      !transfersData?.destination_set
    ) {
      const handlePaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text').trim();
        if (pastedData) {
          dispatch(setLoaderVisibility(true));
          handlePackageScanLoadStock(pastedData);
        }
      };
      window.addEventListener('paste', handlePaste);
      return () => {
        window.removeEventListener('paste', handlePaste);
      };
    }
  }, [handlePackageScanLoadStock]);
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.WHT, route: ROUTES.TRANSFERS },
          { text: transfersData?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        {/* New Addition */}
        {transfersData?.state === 'draft' && (
          <div className="scroll-button-y">
            <div className="d-flex justify-content-between mb-3">
              <div className="d-flex justify-content-start">
                {(scanSourceLocationData || scanDestinationLocationData) && (
                  <Scanner
                    setScanData={setScanData}
                    isCamScannerOpen={isCamScannerOpen}
                    setIsCamScannerOpen={setIsCamScannerOpen}
                  />
                )}
                {transfersData?.stock_loaded === false && (
                  <>
                    <Button
                      className={`white-btn d-flex ${scanDestinationLocationData || scanningPackages ? 'disabled' : ''} align-items-center justify-content-center px-4 mx-2`}
                      clickHandler={startScanningSource}
                      disabled={scanDestinationLocationData || scanningPackages}>
                      {scanSourceLocationData ? BUTTON.STOP_SCAN : BUTTON.SCAN_SOURCE_LOCATION}
                    </Button>
                    <Button
                      className={`white-btn d-flex align-items-center ${scanSourceLocationData || scanningPackages ? 'disabled' : ''} justify-content-center px-4`}
                      clickHandler={startScanningDestination}
                      disabled={scanSourceLocationData || scanningPackages}>
                      {scanDestinationLocationData
                        ? BUTTON.STOP_SCAN
                        : BUTTON.SCAN_DESTINATION_LOCATION}
                    </Button>
                    <Button
                      className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2`}
                      clickHandler={handleCancelTransfer}>
                      {BUTTON.CANCEL_TRANSFER}
                    </Button>
                  </>
                )}
              </div>
              <div className="d-flex justify-content-end">
                {transfersData?.stock_loaded === false && transfersData?.location_change && (
                  <>
                    <Button
                      className={`white-btn d-flex align-items-center justify-content-center px-4 mx-2`}
                      clickHandler={handleImmediateTransfer}>
                      {BUTTON.IMMEDIATE_TRANSFER}
                    </Button>
                    <Button
                      className={`white-btn d-flex align-items-center justify-content-center px-4`}
                      clickHandler={handleImmediateUnpack}>
                      {BUTTON.IMMEDIATE_UNPACK}
                    </Button>
                    <Button
                      className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2`}
                      clickHandler={handleLoadStock}>
                      {BUTTON.LOAD_STOCK}
                    </Button>
                  </>
                )}
                {transfersData?.stock_loaded && (
                  <>
                    {transfersData?.state === 'draft' && !transfersData?.destination_set && (
                      <Button
                        className={`white-btn d-flex align-items-center justify-content-center px-4 mx-2 `}
                        clickHandler={handleMarkAsTodo}>
                        {BUTTON.MARK_AS_TODO}
                      </Button>
                    )}
                    {transfersData?.state === 'draft' && transfersData?.destination_set && (
                      <Button
                        className={`white-btn d-flex align-items-center justify-content-center px-4 mx-2`}
                        clickHandler={handleWholeStockTransfer}>
                        {BUTTON.VALIDATE}
                      </Button>
                    )}
                    {(transfersData?.state === 'draft' || transfersData?.state === 'assigned') && (
                      <Button
                        className={`blue-btn d-flex align-items-center justify-content-center px-4`}
                        clickHandler={handleRemoveStock}>
                        {BUTTON.REMOVE_STOCK}
                      </Button>
                    )}
                    {(transfersData?.state === 'draft' || transfersData?.state === 'assigned') && (
                      <Button
                        className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2`}
                        clickHandler={handleCancelTransfer}>
                        {BUTTON.CANCEL_TRANSFER}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {transfersData?.state === 'assigned' && !transfersData?.destination_set && (
          <div className="d-flex align-items-center justify-content-end mb-3">
            <Button
              className={`white-btn d-flex align-items-center justify-content-center px-4`}
              clickHandler={handleValidate}>
              {BUTTON.VALIDATE}
            </Button>
            <Button
              className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2`}
              clickHandler={handleCancelTransfer}>
              {BUTTON.CANCEL_TRANSFER}
            </Button>
          </div>
        )}
        <div className="d-flex justify-content-end">
          {transfersData?.state === 'done' && (
            <Button
              className="white-btn d-flex align-items-center justify-content-center px-3 mb-3"
              clickHandler={handleCreateTransfer}>
              {BUTTON.CREATE_TRANSFER}
            </Button>
          )}
        </div>
        {/* New Addition done */}
        <DetailBox colCount={2} colData={transfersData} dataCompiler={wtDetailBox} />
        {/* Tab Navigation */}
        {transfersData?.stock_loaded &&
          activeTab === 'operations' &&
          transfersData?.state === 'draft' &&
          !transfersData?.destination_set && (
            <div className="d-flex align-items-center justify-content-end">
              {scanningLoadStockPackages && (
                <Scanner
                  setScanData={setScanData}
                  isCamScannerOpen={isCamScannerOpen}
                  setIsCamScannerOpen={setIsCamScannerOpen}
                />
              )}
              {
                <Button
                  className={`white-btn d-flex align-items-center justify-content-center me-2 px-3 mb-3`}
                  clickHandler={startScanningLoadStock}>
                  {scanningLoadStockPackages ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
                </Button>
              }
            </div>
          )}
        {transfersData?.stock_loaded === false &&
          transfersData?.location_change &&
          activeTab === 'operations' &&
          transfersData?.state === 'draft' && (
            <div className="d-flex align-items-center justify-content-end">
              {scanningPackages && (
                <Scanner
                  setScanData={setScanData}
                  isCamScannerOpen={isCamScannerOpen}
                  setIsCamScannerOpen={setIsCamScannerOpen}
                />
              )}
              {
                <Button
                  className={`white-btn d-flex ${scanSourceLocationData || scanDestinationLocationData ? 'disabled' : ''} align-items-center justify-content-center me-2 px-3 mb-3`}
                  clickHandler={startScanningPackages}
                  disabled={scanSourceLocationData || scanDestinationLocationData}>
                  {scanningPackages ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
                </Button>
              }
            </div>
          )}
        <div className="d-flex align-items-center tab-nav bg-white">
          <div
            className={`text-center w-50 flex-fill ${activeTab === 'operations' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('operations')}>
            <h4>{HEADING.PACKAGES}</h4>
          </div>
          <div
            className={`text-center w-50 flex-fill ${activeTab === 'detailOperation' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('detailOperation')}>
            <h4>{HEADING.DETAIL_OPERATIONS}</h4>
          </div>
        </div>
        {activeTab === 'operations' && (
          <InputWithSearch
            availableItem={opsData}
            selectedItem={selectedItemsOps}
            setSelectedItem={setSelectedItemsOps}
            clickedMove={clickedMove}
            setClickedMove={setClickedMove}
            placeHolderText="Search by BLP"
          />
        )}
        {activeTab === 'detailOperation' && (
          <InputWithSearch
            availableItem={data}
            selectedItem={selectedItems}
            setSelectedItem={setSelectedItems}
            clickedMove={clickedMove}
            setClickedMove={setClickedMove}
            placeHolderText="Search by Product"
          />
        )}
        {/* Tab Content */}
        {activeTab === 'operations' && (
          <div className="tab-content detail-view-tab-content">
            <div className="my-3">
              <Table
                columns={
                  transfersData?.destination_set && transfersData?.stock_loaded
                    ? WT_OPERATIONS_COLUMN_DESTINATION_SET
                    : WT_OPERATIONS_COLUMN
                }
                initialData={selectedItemsOps?.length ? selectedItemsOps : opsData}
                setReload={setReload}
                reload={reload}
                className="table-1 detail-view-table"
                clickedMove={clickedMove}
                setClickedMove={setClickedMove}
              />
            </div>
          </div>
        )}
        {activeTab === 'detailOperation' && (
          <div className="tab-content detail-view-tab-content">
            <div className="my-3">
              <Table
                columns={
                  transfersData?.destination_set && transfersData?.stock_loaded
                    ? WT_TRANSFER_DETAIL_COLUMN_DESTINATION_SET
                    : WT_TRANSFER_DETAIL_COLUMN
                }
                initialData={selectedItems?.length ? selectedItems : data}
                popup={popUp}
                setPopUp={setPopUp}
                setPopUpData={setPopUpData}
                className="table-1 detail-view-table"
              />
            </div>
            {popUp && (
              <PopUpBox openPopUpBox={popUp}>
                <QtyUpdate
                  apiCall={handlePhysicallyAvailableQuantityUpdate}
                  setOpenPopUpBox={setPopUp}
                  data={popUpData}
                />
              </PopUpBox>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const QtyUpdate = ({ apiCall, setOpenPopUpBox, data }) => {
  const [physicallyAvailableQuantity, setPhysicallyAvailableQuantity] = useState('');
  const qtyRef = useRef();
  const closePopUp = () => {
    setOpenPopUpBox(false);
  };
  const handleApiCall = () => {
    if (physicallyAvailableQuantity >= 0) {
      setOpenPopUpBox(false);
      const apiData = {
        move_line_id: data.move_line_id,
        physical_qty: +physicallyAvailableQuantity,
      };
      apiCall(apiData);
    } else {
      popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY);
    }
  };

  useEffect(() => {
    qtyRef.current.focus();
  }, []);

  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h4 className=" text-center text-special mb-3 mt-3 "> {POPUP_MSG.ENTER_QTY} </h4>
        <hr />
        <div className="w-100 modal-body py-0 d-flex flex-column  gap-3 justify-content-center align-items-center">
          <div className="d-flex justify-content-between w-100 ">
            <label className="ms-3">Quantity</label>
            <input
              type="number"
              className="ps-2 curve-border me-4"
              placeholder={data?.on_hand_quantity}
              ref={qtyRef}
              onChange={(e) => {
                setPhysicallyAvailableQuantity(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

export const PhysicallyAvailableCheckUncheck = (data) => {
  const dispatch = useDispatch();
  const handleCheckUncheck = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    packageScanLoadStockWT({
      data: { package_level_id: data.package_id, physically_available: false },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        data.setReload(true);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);
  return data.stock_loaded ? (
    <input
      type="checkbox"
      className="checkbox"
      checked={data.physically_available}
      onClick={handleCheckUncheck}
      disabled={data.physically_available === false || data.state !== 'draft'}
    />
  ) : (
    '---'
  );
};

export const RemovePackage = (data) => {
  const dispatch = useDispatch();
  const handleCheckUncheck = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    packageScanWT({
      data: { internal_transfer_id: data.id, package_name: [data.blp], type: 'remove' },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        data.setReload(true);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);
  return data.stock_loaded === false ? (
    <input
      type="checkbox"
      className="checkbox"
      checked={data.blp}
      onClick={handleCheckUncheck}
      disabled={data.state !== 'draft'}
    />
  ) : (
    '---'
  );
};
