import React from 'react';
import { DropDownArrow2 } from '../../assets/icons';
import { capitalizeWords } from '../../utils/misc';
export const DropDown = ({ dropdownMenu, value, setValue, className = '', capitalize }) => {
  const handleDropdownMenu = (item) => {
    setValue(item);
  };
  return (
    <div
      className={`${className} custom-dropdown-3 d-flex align-items-center justify-content-center`}>
      <div className="dropdown w-100">
        <button
          className="btn w-100 dropdown-toggle bg-white d-flex align-items-center justify-content-between"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          {!capitalize
            ? capitalizeWords(value === 'container_id' ? 'invoice_no' : value)
            : value === 'container_id'
              ? 'invoice_no'
              : value}
          <DropDownArrow2 />
        </button>
        <ul className="dropdown-menu bg-white border-0 py-0" aria-labelledby="dropdownMenuButton1">
          {dropdownMenu.map((item, index) => (
            <li
              key={index}
              onClick={() => handleDropdownMenu(item)}
              className={`${item === value ? 'bg-dark-subtle ' : ''}`}>
              <div className="dropdown-item d-flex align-items-center justify-content-start">
                {!capitalize
                  ? capitalizeWords(item === 'container_id' ? 'invoice_no' : item)
                  : item === 'container_id'
                    ? 'invoice_no'
                    : item}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
