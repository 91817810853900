import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const warehouseDelivery = async ({ data = {} }) => {
  const url = API.WAREHOUSE_DELIVERY;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const outgoingScanDelivery = async ({ data = {} }) => {
  const url = API.OUTGOING_SCAN_DELIVERY;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const printLabelDelivery = async ({ data = {} }) => {
  const url = API.PRINT_LABEL_DELIVERY;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const printUpsLabelDelivery = async ({ data = {} }) => {
  const url = API.PRINT_UPS_LABEL;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const printPackingListDelivery = async ({ data = {} }) => {
  const url = API.PRINT_PACKING_LIST_DELIVERY;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const updateShopifyStatus = async ({ data = {} }) => {
  const url = API.UPDATE_SHOPIFY_STATUS;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const deliveryPickedQtyUpdateLA = async ({ data = {} }) => {
  const url = API.UPDATE_PICKED_QTY_LA_DELIVERY;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const deliveryStartPickingLA = async ({ data = {} }) => {
  const url = API.START_PICKING_LA_DELIVERY;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};



