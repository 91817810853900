import * as React from 'react';
export const PickAll = (props) => (
  <svg
    height="26px"
    width="26px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    {...props}>
    <style type="text/css">
      {
        '\n\t.st0{fill:#76C2AF;}\n\t.st1{opacity:0.2;}\n\t.st2{fill:#231F20;}\n\t.st3{fill:#FFFFFF;}\n\t.st4{fill:#4F5D73;}\n\t.st5{fill:#C75C5C;}\n'
      }
    </style>
    <g id="Layer_1">
      <g>
        <circle className="st0" cx={32} cy={32} r={32} />
      </g>
      <g className="st1">
        <g>
          <path
            className="st2"
            d="M46,45c0,2.2-1.8,4-4,4H22c-2.2,0-4-1.8-4-4V25c0-2.2,1.8-4,4-4h20c2.2,0,4,1.8,4,4V45z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st3"
            d="M22,45c-1.1,0-2-0.9-2-2V23c0-1.1,0.9-2,2-2h20c1.1,0,2,0.9,2,2v20c0,1.1-0.9,2-2,2H22z"
          />
        </g>
        <g>
          <path
            className="st4"
            d="M42,23v20H22V23H42 M42,19H22c-2.2,0-4,1.8-4,4v20c0,2.2,1.8,4,4,4h20c2.2,0,4-1.8,4-4V23 C46,20.8,44.2,19,42,19L42,19z"
          />
        </g>
      </g>
      <g className="st1">
        <g>
          <path
            className="st2"
            d="M32.5,41c-0.6,0-1.2-0.3-1.6-0.8l-6.1-8c-0.7-0.9-0.5-2.1,0.4-2.8c0.9-0.7,2.1-0.5,2.8,0.4l4.2,5.5 c2.3-4.2,7.3-11.8,15.3-16.9c0.9-0.6,2.2-0.3,2.8,0.6c0.6,0.9,0.3,2.2-0.6,2.8c-10.2,6.6-15.2,18-15.3,18.1 c-0.3,0.7-0.9,1.1-1.6,1.2C32.6,41,32.5,41,32.5,41z"
          />
        </g>
      </g>
      <g>
        <path
          className="st5"
          d="M32.5,39c-0.6,0-1.2-0.3-1.6-0.8l-6.1-8c-0.7-0.9-0.5-2.1,0.4-2.8c0.9-0.7,2.1-0.5,2.8,0.4l4.2,5.5 c2.3-4.2,7.3-11.8,15.3-16.9c0.9-0.6,2.2-0.3,2.8,0.6c0.6,0.9,0.3,2.2-0.6,2.8c-10.2,6.6-15.2,18-15.3,18.1 c-0.3,0.7-0.9,1.1-1.6,1.2C32.6,39,32.5,39,32.5,39z"
        />
      </g>
    </g>
    <g id="Layer_2" />
  </svg>
);
