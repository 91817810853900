import React from 'react';
import { ActionBox } from '../components/pages/transfers/ActionBox';
import { CheckUncheckTransfer } from '../components/pages/transfers/TransfersDetail';
import { CheckUncheckDelivery } from '../components/pages/deliveries/DeliveryDetail';
import { CheckUncheckPalletTransfer } from '../components/pages/transfers/TrasfersDetailPalletTransfer';
import { ActionBoxWT } from '../components/pages/transfers/ActionBoxWT';
import { PhysicallyAvailableCheckUncheck, RemovePackage } from '../components/pages/transfers/WHTDetail';
import { EditIcon,DeleteRedIcon } from '../assets/icons';
import { ActionBoxDeliveryLA } from '../components/pages/deliveries/ActionBox';


// Warehouse
export const SHIPMENT_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Shipment No',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    sort: true,
    dataField: ['container'],
    valueType: 'String'
  },
  {
    name: 'Shipment Ref.',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['shipment_no'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Partner Name',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['partner_name'],
  },
  {
    name: 'Scheduled Date',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scheduled_date'],
    sort:true,
    valueType:'Date'
  },
  {
    name: 'Package Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['package_count'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'DONE' || status === 'ASSIGNED') {
        statusClass = 'received';
      } else if (status === 'Waiting for Dispatch' || status === 'CANCEL') {
        statusClass = 'waiting';
      } else if (status === 'In Transit') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const TRANSFER_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Reference',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reference'],
  },
  {
    name: 'From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'Scheduled date',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scheduled_date'],
    sort :true,
    valueType: "Date"
  },
  {
    name: 'Source Document',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['source_document'],
    sort:true,
    valueType: "String"
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'DONE' || status === 'CONFIRMED') {
        statusClass = 'received';
      } else if (status === 'WAITING' || status === 'DRAFT' || status === 'CANCEL') {
        statusClass = 'waiting';
      } else if (status === 'ASSIGNED' || status==='IN_PROGRESS') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const TRANSFER_DETAIL_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Pick From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blpName'],
  },
  {
    name: 'Reserve Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reserve_quantity'],
  },
  {
    name: 'Done Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['done_quantity'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['move_line_id', 'checked'],
    customFormatter: ({ checked, move_line_id, setReload }) => {
      return (
        <CheckUncheckTransfer checked={checked} move_line_id={move_line_id} setReload={setReload} />
      );
    },
  },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['blpName', 'scan_picked', 'done_quantity', 'reserve_quantity', 'move_line_id','create_delivery_package', 'is_la_delivery'],
    customFormatter: ({
      blpName,
      is_la_delivery,
      scan_picked,
      done_quantity,
      reserve_quantity,
      move_line_id,
      popup,
      setPopUp,
      setPopUpData,
      create_delivery_package
    }) => {
      return (
        <ActionBox
        is_la_delivery={is_la_delivery}
          blpName={blpName}
          create_delivery_package={create_delivery_package}
          move_line_id={move_line_id}
          scan_picked={scan_picked}
          done_quantity={done_quantity}
          reserve_quantity={reserve_quantity}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
];
export const TRANSFER_DETAIL_PALLET_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Pick From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blpName'],
  },
  {
    name: 'Reserve Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reserve_quantity'],
  },
  {
    name: 'Done Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['done_quantity'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['checked'],
    customFormatter: ({ checked, setReload }) => {
      return <CheckUncheckPalletTransfer checked={checked} setReload={setReload} />;
    },
  },
];

export const DELIVERY_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Reference',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reference'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'Scheduled Date',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scheduled_date'],
    sort:true,
    valueType: "Date"
  },
  {
    name: 'Source Document',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['source_document'],
    sort:true,
    valueType: "String"
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'CONFIRMED' || status === 'DONE') {
        statusClass = 'received';
      } else if (status === 'WAITING' || status === 'CANCEL' || status === 'DRAFT') {
        statusClass = 'waiting';
      } else if (status === 'ASSIGNED' || status === 'IN_PROGRESS') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const DELIVERY_OPERATIONS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Demand',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['demand'],
  },
  {
    name: 'Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['quantity'],
  },
  {
    name: 'Unit',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['unit'],
  },
];

export const DELIVERY_LA_OPERATIONS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Demand',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['demand'],
  },
  {
    name: 'Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['quantity'],
  },
  {
    name: 'Total Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['total_picked_quantity'],
  },
  {
    name: 'Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['picked_quantity'],
  },
  {
    name: 'Scanned Boxed',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scanned_boxes'],
  },
  {
    name: 'Unit',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['unit'],
  },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['move_id','picked_quantity','is_start_picking_pressed', 'state'],
    customFormatter: ({
      move_id, 
      state,
      picked_quantity,
      is_start_picking_pressed,
      popup,
      setPopUp,
      setPopUpData,
    }) => {
      return (
        <ActionBoxDeliveryLA
        is_start_picking_pressed={is_start_picking_pressed}
          move_id={move_id}
          state={state}
          picked_quantity={picked_quantity}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
];

export const DELIVERY_DETAIL_OPERATION_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blpName'],
  },
  {
    name: 'Reserve Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reserve_quantity'],
  },
  {
    name: 'Done Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['done_quantity'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['checked', 'move_line_id'],
    customFormatter: ({ checked, move_line_id, setReload }) => {
      return (
        <CheckUncheckDelivery checked={checked} move_line_id={move_line_id} setReload={setReload} />
      );
    },
  },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['blpName', 'scan_picked', 'done_quantity', 'reserve_quantity', 'move_line_id','create_delivery_package', 'is_la_delivery'],
    customFormatter: ({
      blpName,
      move_line_id,
      create_delivery_package,
      scan_picked,
      done_quantity,
      reserve_quantity,
      is_la_delivery,
      popup,
      setPopUp,
      setPopUpData,
    }) => {
      return (
        <ActionBox
        is_la_delivery={is_la_delivery}
          move_line_id={move_line_id}
          create_delivery_package={create_delivery_package}
          blpName={blpName}
          scan_picked={scan_picked}
          done_quantity={done_quantity}
          reserve_quantity={reserve_quantity}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
];

export const PALLETING_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Shipment No.',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['container_id'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Shipment Ref.',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['shipment_id'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Pallets Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['pellets'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Package Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['packages'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Expected Date/Time',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['expected_date'],
    sort:true,
    valueType:'Date'
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'DONE') {
        statusClass = 'received';
      } else if (status === 'ASSIGNED') {
        statusClass = 'waiting';
      } else if (status === 'DRAFT') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const RECEPTION_COLUMNS = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Package Type',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['package_type'],
  },
  {
    name: 'Package Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['total_packages'],
  },
  {
    name: 'Pallets Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['pallets_required'],
  },
];
export const SKU_COLUMN = [
  {
    name: 'SKU',
    classNameTH: 'd-flex justify-content-start px-5 ',
    classNameTD: 'd-flex justify-content-start align-items-center px-5 border ',
    dataField: ['name'],
  },
  {
    name: 'Quantity',
    classNameTH: 'option-th',
    classNameTD: 'option-td border',
    dataField: ['quantity'],
  },
  {
    name: 'Action',
    classNameTH: 'd-flex justify-content-center ',
    classNameTD: 'option-td border',
    isCustom: true,
    dataField: ['id', 'name', 'quantity'],
    customFormatter: ({ id, name, quantity, func }) => {
      return (
        <div className="d-flex justify-content-evenly h-100 align-items-center gap-3">
          <div
            onClick={() => {
              func({ data: { id, name, quantity }, action: 'edit' });
            }}
            className="w-50 h-100 m-0 d-flex align-items-center justify-content-center action-div-3">
            <EditIcon />
          </div>
          <div
            onClick={() => {
              func({ data: { id, name, quantity }, action: 'delete' });
            }}
            className="w-50 h-100 m-0 d-flex align-items-center justify-content-center action-div-3">
            <DeleteRedIcon />
          </div>
        </div>
      );
    },
  },
];
export const WT_OPERATIONS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blp'],
  },
  {
    name: 'Source Location',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['source_location'],
  },
  {
    name: 'Destination Location',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['destination_location'],
  },
  {
    name: 'Physically Available',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['physically_available', 'blp', 'package_id','stock_loaded', 'state'],
    customFormatter: ({ physically_available, blp, package_id, setReload, stock_loaded, state }) => {
      return (
        <PhysicallyAvailableCheckUncheck
          stock_loaded= {stock_loaded}
          state={state}
          physically_available={physically_available}
          blp={blp}
          package_id={package_id}
          setReload={setReload}
        />
      );
    },
  },
  {
    name: 'Remove Package',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: [ 'blp', 'id', 'stock_loaded', 'state'],
    customFormatter: ({ blp, id, stock_loaded, setReload, state }) => {
      return (
        <RemovePackage
          blp={blp}
          state={state}
          stock_loaded={stock_loaded}
          id={id}
          setReload={setReload}
        />
      );
    },
  },
];

export const WT_TRANSFER_DETAIL_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Pick From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'On-hand Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['on_hand_quantity'],
  },
  {
    name: 'Physically Available Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['physically_available_quantity'],
  },
  // {
  //   name: 'Done Quantity',
  //   classNameTH: 'option-td',
  //   classNameTD: 'option-td',
  //   dataField: ['done_quantity'],
  // },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['physically_available_quantity', 'on_hand_quantity', 'move_line_id', 'state'],
    customFormatter: ({
      physically_available_quantity,
      on_hand_quantity,
      move_line_id,
      state,
      popup,
      setPopUp,
      setPopUpData,
    }) => {
      return (
        <ActionBoxWT
          move_line_id={move_line_id}
          state={state}
          physically_available_quantity={physically_available_quantity}
          on_hand_quantity={on_hand_quantity}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
];
