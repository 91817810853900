import React from 'react';
import { useLocation } from 'react-router-dom';
import { TransfersDetail } from './TransfersDetail';
import { TransfersDetailPalletTransfer } from './TrasfersDetailPalletTransfer';
import { WHTDetail } from './WHTDetail';
import { MixedTransfer } from './MixedTransfer';

export const TransferSelector = () => {
  const { state } = useLocation(location.state);
  return (
    <>
      {state?.is_warehouse_transfer ? (
        <WHTDetail state={state} />
      ) : state?.pallet_transfer === true ? (
        <TransfersDetailPalletTransfer state={state} />
      ) : state?.mixed_transfer ? (
        <MixedTransfer state={state} />
      ) : (
        <TransfersDetail state={state} />
      )}
    </>
  );
};
