import React from 'react';
import { DetailsIcon } from '../../../assets/icons/DetailsIcon';

export const ActionBoxCheckDetails = ({ move_id, clickedMove, setClickedMove, setActiveTab }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (clickedMove !== move_id) {
      setClickedMove(move_id);
    }
    setActiveTab('detailOperation');
  };

  return (
    <div className="td-num">
      <DetailsIcon onClick={handleClick} />
    </div>
  );
};
