import * as React from 'react';
export const StockLookupIcon = ({ width = 23, height = 24, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="#4B5564">
        <path d="M139.13,0v233.739H384V0H139.13z M328.348,100.174H194.783V66.783h133.565V100.174z" />
        <path d="M5.565,267.13V512h233.739V267.13H5.565z M183.652,367.304H61.217v-33.391h122.435V367.304z" />
        <path d="M272.696,267.13V512h233.739V267.13H272.696z M450.783,367.304H328.348v-33.391h122.435V367.304z" />
      </g>
    </svg>
  );
};
