import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';

export const TitleBar = ({ title }) => {
  const navigate = useNavigate();
  const handleTitleClick = (index) => {
    if(index>=1 && (title[index].route!==ROUTES.NULL && title[index].route!==''))
      navigate(-1);
    else
      index + 1 < title.length && title[index].route !== '' ? navigate(title[index].route) : '';
  };

  return (
    <div className="title-bar bg-white w-100 mb-3">
      <h4 className="mb-0">
        {title.map((sub, index) =>
          sub.text !== '' ? (
            <span key={index} onClick={() => handleTitleClick(index)} style={{ cursor: (index > 1 && title.length===2) ? 'pointer' : 'default' }}>
              <span> {index > 0 ? ' > ' : ''}</span>
              {index + 1 < title.length && title[index].route !== '' ? (
                <Link className="breadcrumb">{sub.text}</Link>
              ) : (
                sub.text
              )}
            </span>
          ) : (
            ''
          ),
        )}
      </h4>
    </div>
  );
};
