import React, { useState, useEffect, useRef } from 'react';
import { BUTTON, MULTI_STEP_PICKUP_LINES_COLUMN, TITLES } from '../../../constants';
import { PopUpBox, Scanner, Table, TitleBar } from '../../common';
import { getLocalStorageItem, popup } from '../../../utils';
import {
  locationDataCompiler,
  multiStepPickupDataCompiler,
} from '../../../utils/data_compiler/transferCompiler';
import { POPUP_TYPE, ROUTES } from '../../../constants';
import { useDebounce } from '../../../hooks';
import { createNewMoveLines, showMoveLines, verifyTransferLocation } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '../../shared';
import { ScanIcon } from '../../../assets/icons';
import scan_duplicate from '../../../assets/sounds/duplicate.mp3';

const MultiStepPickupCreateDeliveries = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [availableLines, setAvailableLines] = useState([]);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const [quantity, setQuantity] = useState('');
  const [showPopup, setShowPopup] = useState(0);
  const [popUpData, setPopUpData] = useState('');
  let successAudio = new Audio(scan_duplicate);

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';
  const startScanning = () => {
    setScanning((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    isCameraScanBool && !isCamScannerOpen ? setScanning(false) : '';
  });

  const getLocationVerified = useDebounce((scanData) => {
    successAudio.play();
    let highlight_index = -1;
    // Find the highlight index based on scanData
    for (let i = 0; i < availableLines?.move_line_ids?.length; i++) {
      const item = availableLines.move_line_ids[i];
      if (item.location_id.barcode === scanData) {
        highlight_index = i;
        break;
      }
    }
    if (highlight_index !== -1) {
      dispatch(setLoaderVisibility(false));
      getLocalStorageItem('isCameraScan') === true ? setScanning(false) : '';
      setScanData('');
      setHighlightIndex(highlight_index);
      popup(
        POPUP_TYPE.SUCCESS,
        `Line already exists with similar location at row ${highlight_index + 1}`,
      );
      if (!availableLines?.move_line_ids[highlight_index]?.scan_picked) {
        verifyTransferLocation({
          data: { location_barcode: [scanData], move_id: state?.move_id, for_open_location: true },
        })
          .then(() => {
            dispatch(setLoaderVisibility(false));
            getMoveLines();
          })
          .catch(() => {
            dispatch(setLoaderVisibility(false));
            // popup(POPUP_TYPE.ERR, error.message);
          });
      }
    } else {
      {
        verifyTransferLocation({
          data: { location_barcode: [scanData], move_id: state?.move_id, for_open_location: true },
        })
          .then((response) => {
            dispatch(setLoaderVisibility(false));
            setLocationData(locationDataCompiler(response?.data));
          })
          .catch(() => {
            dispatch(setLoaderVisibility(false));
            // popup(POPUP_TYPE.ERR, error.message);
          });
        getLocalStorageItem('isCameraScan') === true ? setScanning(false) : '';
        setHighlightIndex(-1);
        setScanData('');
      }
    }
  }, 400);

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      getLocationVerified(scanData);
    }
  }, [scanData]);

  const createNewLine = useDebounce(() => {
    if (
      !locationData?.[0]?.location_name ||
      !locationData?.[0]?.location_id ||
      !state?.move_id ||
      typeof quantity !== 'number' ||
      isNaN(quantity) ||
      quantity <= 0
    ) {
      popup(POPUP_TYPE.ERR, 'Cannot create, required parameters are missing.');
      return;
    }
    dispatch(setLoaderVisibility(true));
    createNewMoveLines({
      data: {
        lines: [
          {
            location_name: locationData?.[0]?.location_name,
            location_id: locationData?.[0]?.location_id,
            move_id: state?.move_id,
            quantity: parseInt(quantity),
            action: 'create',
          },
        ],
        for_houston_single_step: true,
      },
    })
      .then(() => {
        setLocationData([]);
        setHighlightIndex(-1);
        setQuantity('');
        getMoveLines();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const updateQtyAPI = useDebounce((move_id, move_line_id, location_name, location_id, doneQty) => {
    if (
      !move_id ||
      !move_line_id ||
      !location_name ||
      !location_id ||
      doneQty === undefined ||
      doneQty === null
    ) {
      popup(POPUP_TYPE.ERR, 'Cannot update, required parameters are missing.');
      return;
    }
    dispatch(setLoaderVisibility(true));
    createNewMoveLines({
      data: {
        lines: [
          {
            location_name: location_name,
            location_id: location_id,
            move_id: move_id,
            move_line_id: move_line_id,
            quantity: parseInt(doneQty),
            action: 'update',
          },
        ],
        for_houston_single_step: true,
      },
    })
      .then(() => {
        setLocationData([]);
        setQuantity('');
        getMoveLines();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const deleteLineAPI = useDebounce(
    (move_id, move_line_id, location_name, location_id, quantity) => {
      if (
        !move_id ||
        !move_line_id ||
        !location_name ||
        !location_id ||
        quantity === undefined ||
        quantity === null
      ) {
        popup(POPUP_TYPE.ERR, 'Cannot delete, required parameters are missing.');
        return;
      }
      dispatch(setLoaderVisibility(true));
      createNewMoveLines({
        data: {
          lines: [
            {
              location_name: location_name,
              location_id: location_id,
              move_id: move_id,
              move_line_id: move_line_id,
              quantity: parseInt(quantity),
              action: 'delete',
            },
          ],
          for_houston_single_step: true,
        },
      })
        .then(() => {
          setLocationData([]);
          setHighlightIndex(-1);
          setQuantity('');
          getMoveLines();
        })
        .catch((error) => {
          dispatch(setLoaderVisibility(false));
          popup(POPUP_TYPE.ERR, error.message);
        });
    },
    400,
  );

  const getMoveLines = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    showMoveLines({
      data: { move_id: state?.move_id, from_houston_single_step: true },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setAvailableLines(response?.data[0]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getMoveLines({ move_id: state?.move_id });
  }, []);

  const handleCreate = () => {
    if (!locationData[0]?.location_name || !quantity) {
      popup(POPUP_TYPE.ERR, 'Location name and quantity both are required.');
      return;
    }
    createNewLine();
  };

  const handleDelete = () => {
    setLocationData([]);
    setQuantity('');
  };

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DELIVERY, route: ROUTES.DELIVERIES },
          {
            text: state?.name,
            route: `${ROUTES.DELIVERY_DETAIL}/${state?.name}`,
          },
          { text: TITLES.CREATE_MULTI_STEP_LINES, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        <div className="product-info my-3 px-3">
          <div className="bg-light p-3 mb-2 border rounded">
            <div className="row">
              {/* First Column */}
              <div className="col-md-8 mb-2">
                <span className="d-block mx-3">
                  <strong className="text-muted">Product:</strong>{' '}
                  {availableLines?.product_id?.display_name}
                </span>
                <span className="d-block mx-3">
                  <strong className="text-muted">Remaining Quantity:</strong>{' '}
                  {availableLines?.loose_qty - availableLines?.done_qty}
                </span>
              </div>
              {/* Second Column */}
              <div className="col-md-4 mb-2">
                <span className="d-block">
                  <strong className="text-muted">Demand Quantity:</strong>{' '}
                  {availableLines?.loose_qty}
                </span>
                <span className="d-block">
                  <strong className="text-muted">Done Quantity:</strong> {availableLines?.done_qty}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end">
          {scanning && (
            <Scanner
              setScanData={setScanData}
              isCamScannerOpen={isCamScannerOpen}
              setIsCamScannerOpen={setIsCamScannerOpen}
            />
          )}
          <Button
            className={`white-btn d-flex align-items-center justify-content-center mb-3 px-3 me-2`}
            clickHandler={startScanning}>
            {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_SOURCE_LOCATION} <ScanIcon />
          </Button>
        </div>

        {/* Create and Delete Buttons Section */}
        <div className="d-flex align-items-center my-3 mx-2">
          <input
            type="text"
            className="form-control me-2"
            placeholder="Location Name"
            value={locationData?.[0]?.location_name || ''}
            readOnly
            disabled={!locationData?.[0]?.location_name}
          />
          <input
            type="number"
            className="form-control me-2"
            placeholder="Quantity"
            value={quantity}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              // const maxQuantity = availableLines?.loose_qty - availableLines?.done_qty;
              if (
                value >= 1
                // && value <= maxQuantity
              ) {
                setQuantity(value);
              } else if (value === 0 || isNaN(value)) {
                setQuantity('');
              }
            }}
            disabled={!locationData?.[0]?.location_name}
          />
          <Button
            className={`blue-btn d-flex align-items-center justify-content-center px-3 me-2 ${!locationData?.[0]?.location_name || !quantity ? 'disabled' : ''}`}
            clickHandler={handleCreate}
            disabled={!locationData?.[0]?.location_name || !quantity}>
            {BUTTON.ADD}
          </Button>
          <Button
            className={`blue-btn d-flex align-items-center justify-content-center px-3 ${!locationData?.[0]?.location_name && !quantity ? 'disabled' : ''}`}
            clickHandler={handleDelete}
            disabled={!locationData?.[0]?.location_name && !quantity}>
            {BUTTON.REMOVE}
          </Button>
        </div>

        {/* Table Section */}
        <div className="data-content h-100">
          <div className="w-100 h-100 editing-container">
            <div className="row h-100 d-flex align-items-center justify-content-center">
              <div className="edit-container py-4 px-lg-5 px-4 overflow-hidden table-new-div bg-white data-box w-100 h-100">
                <div className="mt-2 mb-2 overflow-scroll h-75">
                  <Table
                    className="table-4"
                    columns={MULTI_STEP_PICKUP_LINES_COLUMN}
                    initialData={availableLines}
                    compiler={{
                      use: true,
                      tool: multiStepPickupDataCompiler,
                    }}
                    popup={showPopup}
                    setPopUp={setShowPopup}
                    setPopUpData={setPopUpData}
                    highlightIndex={highlightIndex}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopup === 1 && (
          <PopUpBox openPopUpBox={showPopup}>
            <QtyUpdate
              apiCall={updateQtyAPI}
              setOpenConfirmationBox={setShowPopup}
              data={popUpData}
              remaining_quantity={availableLines?.loose_qty - availableLines?.done_qty}
            />
          </PopUpBox>
        )}
        {showPopup === 2 && (
          <PopUpBox openPopUpBox={showPopup}>
            <DeleteLine
              apiCall={deleteLineAPI}
              data={popUpData}
              setOpenConfirmationBox={setShowPopup}
            />
          </PopUpBox>
        )}
      </div>
    </>
  );
};
export default MultiStepPickupCreateDeliveries;

const QtyUpdate = ({ setOpenConfirmationBox, apiCall, data, remaining_quantity }) => {
  const [doneQty, setDoneQty] = useState('');
  const qtyRef = useRef();
  const closePopUp = () => {
    setOpenConfirmationBox(0);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(0);
    apiCall(data.move_id, data.move_line_id, data.location_name, data.location_id, doneQty);
  };

  useEffect(() => {
    qtyRef.current.focus();
  }, []);
  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h4 className=" text-center text-special mb-3 "> Enter Qty. </h4>
        <div className="w-100 modal-body py-0 d-flex flex-column  gap-3 justify-content-center align-items-center">
          <div className="d-flex justify-content-between w-100 ">
            <label className="ms-3">Quantity</label>
            <input
              type="number"
              className="ps-2 curve-border me-4"
              placeholder={`${data.quantity + remaining_quantity}`}
              ref={qtyRef}
              // max={data.quantity + remaining_quantity}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10); // Ensure value is an integer
                // const maxAllowed = data.quantity + remaining_quantity;

                if (
                  !isNaN(value) &&
                  value > 0
                  // && value <= maxAllowed
                ) {
                  setDoneQty(value);
                } else {
                  e.target.value = ''; // Clear the input field
                  setDoneQty(''); // Clear the state
                }
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

const DeleteLine = ({ apiCall, setOpenConfirmationBox, data }) => {
  const closePopUp = () => {
    setOpenConfirmationBox(0);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(0);
    apiCall(data.move_id, data.move_line_id, data.location_name, data.location_id, data.quantity);
  };
  return (
    <>
      <div className="d-flex m-1 ">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className=" text-center text-special mb-3 ">Delete Line</h4>
          <p className="text-center text-special placeholder-color ">
            Are you sure to delete the line?
          </p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CONFIRM}
          </Button>{' '}
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
