import React, { useState, useEffect } from 'react';
import { Button } from '../shared';
import { useDebounce } from '../../hooks';
import { setLoaderVisibility } from '../../redux';
import { updateShippingWeights } from '../../service';
import { POPUP_TYPE } from '../../constants';
import { popup } from '../../utils';
import { useDispatch } from 'react-redux';

const UPSWeightEntry = ({ pickingId, data, setReload, setShowUpsWeightEntry }) => {
  const [packageCount, setPackageCount] = useState(data?.length || '');
  const [packages, setPackages] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const dispatch = useDispatch();

  // If data exists, initialize packages with it
  useEffect(() => {
    if (data?.length) {
      setPackages(
        data.map((pkg) => ({
          name: pkg.short_name,
          shipping_weight: pkg.shipping_weight !== null ? pkg.shipping_weight : '',
        })),
      );
    }
  }, [data]);

  // Update isSaveDisabled in real time
  useEffect(() => {
    setIsSaveDisabled(
      !packageCount || packageCount <= 0 || Number(packageCount) === packages.length,
    );
  }, [packageCount, packages.length]);

  const handleSave = () => {
    if (packageCount > 0) {
      setPackages((prevPackages) => {
        const newPackages = Array.from({ length: packageCount }, (_, index) => {
          return (
            prevPackages[index] || {
              name: `P${String(index + 1).padStart(2, '0')}`,
              shipping_weight: '',
            }
          );
        });
        return newPackages;
      });
    }
  };

  const handleWeightChange = (index, value) => {
    const updatedPackages = [...packages];
    updatedPackages[index].shipping_weight = value === '' ? '' : Number(value);
    setPackages(updatedPackages);
  };

  const handleSubmit = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    const apiData = {
      picking_id: pickingId,
      box_count: packages.length,
      ups_info: packages.map((pkg) => Number(pkg.shipping_weight) || 0),
    };
    updateShippingWeights({ data: apiData })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        setShowUpsWeightEntry(false);
        setReload(true);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleDelete = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    const apiData = {
      picking_id: pickingId,
      box_count: 0,
      ups_info: [],
    };
    updateShippingWeights({ data: apiData })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        setShowUpsWeightEntry(false);
        setReload(true);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const isSubmitDisabled =
    packages.length === 0 ||
    packages.some((pkg) => pkg.shipping_weight === '' || Number(pkg.shipping_weight) === 0) ||
    (data.length === packages.length &&
      packages.every(
        (pkg, index) =>
          data[index] &&
          pkg.name === data[index].short_name &&
          Number(pkg.shipping_weight) === Number(data[index].shipping_weight),
      ));

  return (
    <div className="ups-weight-container">
      <h2>Shipping Weight Details</h2>
      <div className="ups-weight-input">
        <label>Package Count:</label>
        <input
          type="number"
          className="form-control"
          value={packageCount}
          onChange={(e) => setPackageCount(e.target.value)}
          min="1"
        />
        <Button
          className={`blue-btn px-3 py-2 ${isSaveDisabled ? 'disabled' : ''}`}
          clickHandler={handleSave}
          disabled={isSaveDisabled}>
          Save
        </Button>
        {data?.length ? (
          <Button className="blue-btn px-3" clickHandler={handleDelete}>
            Delete Details
          </Button>
        ) : null}
      </div>

      {packages.length > 0 && (
        <table className="ups-table">
          <thead>
            <tr>
              <th>Package Name</th>
              <th>Package Weight</th>
            </tr>
          </thead>
          <tbody>
            {packages.map((pkg, index) => (
              <tr key={index}>
                <td>{pkg.name}</td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={pkg.shipping_weight}
                    onChange={(e) => handleWeightChange(index, e.target.value)}
                    min="0"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {packages.length > 0 && (
        <div className="ups-button-container">
          <Button
            className={`blue-btn px-3 py-2 ${isSubmitDisabled ? 'disabled' : ''}`}
            clickHandler={handleSubmit}
            disabled={isSubmitDisabled}>
            Submit Shipping Weight Details
          </Button>
        </div>
      )}
    </div>
  );
};

export default UPSWeightEntry;
