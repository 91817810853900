import { capitalize } from '../misc';
import React from 'react';

export const transferDataCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let transferData = {
      url: obj.name.replaceAll('/', '_'),
      backorder_id: obj?.backorder_id || 'N/A',
      reference: obj.name || 'N/A',
      name: obj?.name,
      from: obj.location_id?.barcode || obj.location_id?.display_name || 'N/A',
      to: obj?.location_dest_id?.barcode || obj.location_dest_id?.display_name || 'N/A',
      pallet_transfer: obj.pallet_transfer,
      po_number: obj?.po_number || 'N/A',
      scheduled_date: obj?.scheduled_date ? obj?.scheduled_date.split(' ')[0] : 'N/A',
      source_document: obj?.origin || 'N/A',
      status: capitalize(obj.state),
      customer: obj.sale_id?.partner_id?.name || 'N/A',
      shipping_method: capitalize(obj.carrier_id?.delivery_type) || 'N/A',
      shipping_method_name: capitalize(obj.carrier_id?.name) || 'N/A',
      picking_status: capitalize(obj?.picking_status) || 'N/A',
      model: obj?.model,
      data: obj,
    };
    finalData.push(transferData);
  });
  return finalData;
};

export function transferDetailBox(data) {
  let detailBoxData = [
    { name: 'Customer', col: 1, data: data?.sale_id?.partner_id?.name || 'N/A' },
    { name: 'Source Location', col: 1, data: data?.location_id?.display_name || 'N/A' },
    { name: 'Destination Location', col: 1, data: data?.location_dest_id?.display_name || 'N/A' },
    { name: 'Source', col: 1, data: data?.origin || 'N/A' },
    { name: 'PO Number', col: 1, data: data?.po_number || 'N/A' },
    { name: 'Delivery Note', col: 1, data: data?.sale_note || 'N/A' },
    { name: 'Packages', col: 1, data: data?.packages_count || 'N/A' },
    { name: 'Scanned Packages', col: 1, data: data?.total_scanned_packages || 'N/A' },
    { name: 'Scheduled Date', col: 2, data: data?.scheduled_date || 'N/A' },
    { name: 'Packing Preference', col: 2, data: data?.packing_preference || 'N/A' },
    { name: 'Picking Status', col: 2, data: capitalize(data?.picking_status) || 'N/A' },
    { name: 'Status', col: 2, data: capitalize(data?.state) || 'N/A' },
    { name: 'Backorder Of', col: 1, data: data?.backorder_id || 'N/A' },
    { name: 'Shipping', col: 2, data: capitalize(data?.carrier_id?.name) || 'N/A' },
    {
      name: 'Signature',
      col: 2,
      data: data?.signature_image ? (
        <img
          className="border"
          src={`data:image/png;base64,${data.signature_image}`}
          alt="Signature"
          style={{ width: '150px', height: '80px', objectFit: 'contain' }}
        />
      ) : (
        <span>N/A</span>
      ),
    },
  ];
  return detailBoxData;
}

export function wtDetailBox(data, stock_loaded, destination_set) {
  let detailBoxData = [
    { name: 'Customer', col: 1, data: data?.sale_id?.partner_id?.name || 'N/A' },
    { name: 'Source Location', col: 1, data: data?.location_id?.display_name || 'N/A' },
    { name: 'Destination Location', col: 1, data: data?.location_dest_id?.display_name || 'N/A' },
    { name: 'Source', col: 1, data: data?.origin || 'N/A' },
    { name: 'Scheduled Date', col: 1, data: data?.scheduled_date || 'N/A' },

    { name: 'Backorder Of', col: 1, data: data?.backorder_id || 'N/A' },
    { name: 'Status', col: 1, data: capitalize(data?.state) || 'N/A' },
    { name: 'Box Count', col: 2, data: data?.box_count || '0' },
  ];

  let stockFields = stock_loaded
    ? destination_set
      ? [
          { name: 'Stock Loaded Boxes', col: 2, data: data?.stock_loaded_count || '0' },
          {
            name: 'Source Location Box Count',
            col: 2,
            data: data?.location_source_package_count || '0',
          },
          {
            name: 'Destination Location Box Count',
            col: 2,
            data: data?.location_dest_package_count || '0',
          },
          {
            name: 'Total Boxes at Destination Location',
            col: 2,
            data: data?.total_dest_loc_package_count || '0',
          },
        ]
      : [
          { name: 'Stock Loaded Boxes', col: 2, data: data?.stock_loaded_count || '0' },
          {
            name: 'Physical Scanned Boxes',
            col: 2,
            data: data?.physical_scanned_boxes_count || '0',
          },
          {
            name: 'Source Location Box Count',
            col: 2,
            data: data?.location_source_package_count || '0',
          },
          {
            name: 'Destination Location Box Count',
            col: 2,
            data: data?.location_dest_package_count || '0',
          },
          {
            name: 'Total Boxes at Destination Location',
            col: 2,
            data: data?.total_dest_loc_package_count || '0',
          },
        ]
    : [
        { name: 'Scanned Boxes', col: 2, data: data?.scanned_boxes_count || '0' },
        {
          name: 'Source Location Box Count',
          col: 2,
          data: data?.location_source_package_count || '0',
        },
        {
          name: 'Destination Location Box Count',
          col: 2,
          data: data?.location_dest_package_count || '0',
        },
        {
          name: 'Total Boxes at Destination Location',
          col: 2,
          data: data?.total_dest_loc_package_count || '0',
        },
      ];

  return [...detailBoxData, ...stockFields];
}

export function transferDetailBoxPallet(data) {
  let detailBoxData = [
    { name: 'Customer', col: 1, data: data?.sale_id?.partner_id?.name || 'N/A' },
    { name: 'Source Location', col: 1, data: data?.location_id?.barcode || 'N/A' },
    { name: 'Destination Location', col: 1, data: data?.location_dest_id?.barcode || 'N/A' },
    { name: 'Status', col: 1, data: capitalize(data?.state) || 'N/A' },
    { name: 'Source', col: 2, data: data?.origin || 'N/A' },
    { name: 'Backorder Of', col: 1, data: data?.backorder_id || 'N/A' },
    { name: 'Scheduled Date', col: 2, data: data?.scheduled_date || 'N/A' },
    { name: 'Packing Preference', col: 2, data: data?.packing_preference || 'N/A' },
    { name: 'Shipping', col: 2, data: capitalize(data?.carrier_id?.name) || 'N/A' },
  ];
  return detailBoxData;
}

export const innerTransferOperationsCompiler = (data) => {
  let finalData = [];
  data?.move_ids?.map((obj) => {
    let transferOperationData = {
      estimated_boxes: obj?.estimated_boxes,
      total_picked_quantity: obj?.total_picked_qty,
      scan_picked_quantity:
        data?.create_delivery_package === false && data?.picking_type === 'loose_pieces'
          ? 'N/A'
          : obj?.scan_picked_qty,
      is_check_picking: data?.is_check_picking,
      is_picking_checked: data?.is_picking_checked,
      picking_status: data?.picking_status,
      check_move: obj?.check_move,
      checked: obj?.checked,
      finalize_picked: data?.finalize_picked,
      product: obj.product_id?.display_name,
      model: data?.model,
      demand: obj.product_uom_qty,
      quantity: obj.quantity,
      unit: obj.product_uom?.name,
      multi_location_pick: obj?.multi_location_pick,
      move_id: obj?.id,
      name: data?.name,
      state: data?.state,
    };
    finalData.push(transferOperationData);
  });
  return finalData;
};

export const innerTransferDetailedOperationCompiler = (data) => {
  let finalData = [];
  if (data?.move_ids?.length > 0) {
    data?.move_ids?.forEach((obj) => {
      if (obj.move_line_ids.length !== 0) {
        obj.move_line_ids.forEach((moveLine) => {
          let innerTransferData = {
            move_id: obj?.id,
            check_move: obj.check_move,
            is_move_checked: obj.checked,
            create_delivery_package: data?.create_delivery_package,
            is_la_delivery: data?.is_la_delivery,
            move_line_id: moveLine?.id,
            product: obj.product_id?.display_name,
            picking_status: data?.picking_status,
            from: moveLine?.location_id?.display_name,
            to: moveLine?.location_dest_id?.display_name,
            blpName: moveLine?.result_package_id?.name || 'N/A',
            package_id: moveLine?.result_package_id?.id,
            checked: moveLine?.checked,
            finalize_picked: data?.finalize_picked,
            scan_picked: moveLine?.scan_picked,
            reserve_quantity: moveLine?.quantity,
            name: data?.name,
            done_quantity: moveLine?.done_qty,
            model: data?.model,
            status: false,
            data: obj,
          };
          finalData.push(innerTransferData);
        });
      }
    });
  }
  return finalData;
};

export const innerTransferDetailedOperationPalletTransferCompiler = (data) => {
  let finalData = [];
  if (data) {
    data.forEach((obj) => {
      if (obj.move_line_ids.length !== 0) {
        obj.move_line_ids.forEach((moveLine) => {
          let innerTransferData = {
            move_id: obj?.id,
            move_line_id: moveLine?.id,
            product: obj.product_id?.display_name,
            from: moveLine?.location_id?.barcode,
            to: moveLine?.location_dest_id?.barcode || 'N/A',
            blpName: moveLine?.result_package_id?.name || 'N/A',
            checked: moveLine?.checked,
            name: data?.name,
            scan_picked: moveLine?.scan_picked,
            reserve_quantity: moveLine?.initial_reserve_qty,
            done_quantity: moveLine?.quantity,
            status: false,
            data: obj,
          };
          finalData.push(innerTransferData);
        });
      } else {
        let innerTransferData = {
          move_id: obj?.id,
          product: obj.product_id?.display_name,
          from: obj?.location_id?.barcode,
          to: obj?.location_dest_id?.barcode,
          blp: 'N/A',
          checked: false,
          scan_picked: false,
          reserve_quantity: obj?.product_uom_qty,
          done_quantity: obj?.quantity,
          data: obj,
        };
        finalData.push(innerTransferData);
      }
    });
  }
  return finalData;
};

export const innerWtOperationsCompiler = (data) => {
  let finalData = [];
  if (data?.package_level_ids?.length > 0) {
    data?.package_level_ids?.map((obj) => {
      let transferOperationData = {
        stock_loaded: data.stock_loaded,
        destination_set: data?.destination_set,
        state: data?.state,
        id: data?.id,
        name: data?.name,
        blp: obj?.package_id?.name,
        blpName: obj?.package_id?.name,
        package_id: obj?.id,
        source_location: obj?.location_id?.display_name,
        destination_location: obj?.location_dest_id?.display_name,
        physically_available: obj?.physically_available,
      };
      finalData.push(transferOperationData);
    });
  } else {
    data?.custom_package_level_ids?.map((obj) => {
      let transferOperationData = {
        stock_loaded: data.stock_loaded,
        id: data?.id,
        state: data?.state,
        blp: obj?.package_id?.name,
        package_id: obj?.id,
        source_location: obj?.location_id?.display_name,
        destination_location: obj?.location_dest_id?.display_name,
        physically_available: obj?.physically_available,
      };
      finalData.push(transferOperationData);
    });
  }
  return finalData;
};

export const wtInnerDetailedOperationCompiler = (data) => {
  let finalData = [];
  if (data) {
    data?.move_ids?.forEach((obj) => {
      if (obj.move_line_ids.length !== 0) {
        obj.move_line_ids.forEach((moveLine) => {
          let innerTransferData = {
            state: data?.state,
            move_line_id: moveLine?.id,
            product: obj.product_id?.display_name,
            from: moveLine?.location_id?.display_name,
            name: data?.name,
            to: moveLine?.location_dest_id?.display_name,
            on_hand_quantity: moveLine?.on_hand_qty,
            physically_available_quantity: moveLine?.physical_available_qty,
            done_quantity: moveLine?.done_qty,
            model: data?.model,
            status: false,
            data: obj,
          };
          finalData.push(innerTransferData);
        });
      }
    });
  }
  return finalData;
};

export const deliveryPackageDataCompiler = (data) => {
  if (data.length !== 0) {
    const finalData = data?.map((obj) => {
      return {
        id: obj.product_id[0].id,
        name: obj.product_id[0].display_name,
        quantity: obj.quantity,
      };
    });
    return finalData;
  }
};

export const multiStepPickupDataCompiler = (data) => {
  let finalData = [];
  if (data && Array.isArray(data.move_line_ids)) {
    finalData = data.move_line_ids.map((line) => ({
      location_name: line?.location_id?.display_name || 'N/A',
      location_id: line?.location_id?.id || 'N/A',
      move_line_id: line?.id,
      quantity: line?.quantity || 0,
      scan_picked: line?.scan_picked,
      move_id: data?.id,
    }));
  }
  return finalData;
};

export const locationDataCompiler = (data) => {
  let finalData = [];
  if (data && Array.isArray(data)) {
    finalData = data.map((obj) => ({
      location_name: obj?.display_name || 'N/A',
      location_id: obj?.id || 'N/A',
    }));
  }
  return finalData;
};

export function mixedTransferDetailBox(data) {
  let detailBoxData = [
    { name: 'Customer', col: 1, data: data?.sale_id?.partner_id?.name || 'N/A' },
    { name: 'Source Location', col: 1, data: data?.location_id?.display_name || 'N/A' },
    { name: 'Destination Location', col: 1, data: data?.location_dest_id?.display_name || 'N/A' },
    { name: 'Source', col: 1, data: data?.origin || 'N/A' },
    { name: 'Backorder Of', col: 1, data: data?.backorder_id || 'N/A' },
    { name: 'Scheduled Date', col: 2, data: data?.scheduled_date || 'N/A' },
    { name: 'Packing Preference', col: 2, data: data?.packing_preference || 'N/A' },
    { name: 'Status', col: 2, data: capitalize(data?.state) || 'N/A' },
    { name: 'Shipping', col: 2, data: capitalize(data?.carrier_id?.name) || 'N/A' },
  ];
  return detailBoxData;
}

export const mixedTransferOperationsCompiler = (data) => {
  let finalData = [];
  if (data?.package_level_ids?.length) {
    data.package_level_ids.map((obj) => {
      finalData.push({
        state: data?.state,
        picking_id: data?.id,
        blpName: obj?.package_id?.name,
        mix_scanned: obj?.mix_scanned,
        name: data?.name,
        package_id: obj?.package_id?.id,
        from: obj?.location_id?.display_name,
        to: obj?.location_dest_id?.display_name,
      });
    });
  }
  return finalData;
};

export const mixedTransferDetailedOperationsCompiler = (data) => {
  let finalData = [];
  if (data?.package_level_ids?.length) {
    data.package_level_ids.forEach((obj) => {
      obj?.package_id?.quant_ids?.forEach((quant) => {
        finalData.push({
          blpName: obj?.package_id?.name,
          quantity: quant?.quantity,
          product: quant?.product_id?.display_name,
          lot: quant?.lot_id?.name,
        });
      });
    });
  }
  return finalData;
};

export const upsWeightsCompiler = (data) => {
  return data.map((pkg) => ({
    id: pkg.id,
    package_name: pkg?.short_name,
    package_weight: pkg?.shipping_weight || 0,
  }));
};
