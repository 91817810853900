import React, { useEffect, useRef, useState } from 'react';
import { DetailBox, InputWithSearch, PopUpBox, Scanner, Table, TitleBar } from '../../common';
import {
  BUTTON,
  TRANSFER_OPERATIONS_COLUMN_CHECK,
  HEADING,
  POPUP_TYPE,
  ROUTES,
  TITLES,
  TRANSFER_DETAIL_COLUMN,
  TRANSFERS_MULTI_PICK_OPERATIONS_COLUMN,
  UPS_WEIGHTS_COLUMN,
} from '../../../constants';
import {
  innerTransferDetailedOperationCompiler,
  innerTransferOperationsCompiler,
  transferDetailBox,
  upsWeightsCompiler,
} from '../../../utils/data_compiler/transferCompiler';
import { Button } from '../../shared';
import { ScanIcon } from '../../../assets/icons';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import {
  createTransferWT,
  finalizePick,
  markPick,
  printLabelDelivery,
  printPackingListDelivery,
  printUpsLabelDelivery,
  scanLocationkWT,
  warehouseCheckPicking,
  warehouseCheckUncheckMove,
  warehousePackageScan,
  warehouseSign,
  warehouseTransfer,
  warehouseTransferQtyUpdate,
  warehouseValidatePicking,
} from '../../../service';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocalStorageItem, popup } from '../../../utils';
import scan_duplicate from '../../../assets/sounds/duplicate.mp3';
import scan_failed from '../../../assets/sounds/error.mp3';
import SignatureCanvas from 'react-signature-canvas';
import UPSWeightEntry from '../../common/UpsWeightsEntry';

export const TransfersDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation(location?.state);
  const [reload, setReload] = useState(false);
  const [stateData] = useState(state);
  const [clickedMove, setClickedMove] = useState();
  const [transfersData, setTransfersData] = useState(stateData);
  const [opsData, setOpsData] = useState(innerTransferOperationsCompiler(transfersData));
  const [data, setData] = useState(innerTransferDetailedOperationCompiler(transfersData));
  const [activeTab, setActiveTab] = useState('operations');
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [scanDestinationLocationData, setScanDestinationLocationData] = useState(false);
  const [showPopup, setShowPopup] = useState(0);
  const [popUpData, setPopUpData] = useState('');
  const [pageNumber] = useState(1);
  const disabledStates = ['draft', 'waiting', 'done', 'cancel', 'confirmed'];
  const columnsToFilterOut = [];
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const [showUpsWeightEntry, setShowUpsWeightEntry] = useState(false);
  let successAudio = new Audio(scan_duplicate);
  let failedAudio = new Audio(scan_failed);
  const [selectedItems, setSelectedItems] = useState([]);

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  let buttonsDisabled = disabledStates.includes(transfersData?.state);
  let filteredColumns;

  if (buttonsDisabled) {
    columnsToFilterOut.push('Action', 'Status');
    if (transfersData?.state === 'draft' || transfersData?.state === 'waiting')
      columnsToFilterOut.push('Done Quantity');
    filteredColumns = TRANSFER_DETAIL_COLUMN.filter(
      (col) => !columnsToFilterOut.includes(col.name),
    );
  } else if (transfersData?.sale_id === false) {
    columnsToFilterOut.push('Action');
    filteredColumns = TRANSFER_DETAIL_COLUMN.filter(
      (col) => !columnsToFilterOut.includes(col.name),
    );
  } else {
    filteredColumns = TRANSFER_DETAIL_COLUMN;
  }

  const filteredColumnsOperations = TRANSFER_OPERATIONS_COLUMN_CHECK.filter(
    (col) =>
      col.name !== 'Scan Picked Quantity' ||
      !(
        transfersData?.create_delivery_package === false &&
        transfersData?.picking_type === 'loose_pieces'
      ),
  );

  const filteredColumnsOperationsMultiPick = TRANSFERS_MULTI_PICK_OPERATIONS_COLUMN.filter(
    (col) =>
      col.name !== 'Scan Picked Quantity' ||
      !(
        transfersData?.create_delivery_package === false &&
        transfersData?.picking_type === 'loose_pieces'
      ),
  );

  const isMultiStep = () => {
    // return true if transfer is multi-step-pickup transfer
    const CD = innerTransferOperationsCompiler(transfersData);
    for (let obj of CD) {
      if (obj.multi_location_pick) return true;
    }
    return false;
  };

  const hideScan = () => {
    return (
      data?.every((obj) => obj?.blpName === 'N/A') || transfersData?.picking_type === 'loose_pieces'
    );
  };

  const hideCreateTransfer = () => {
    return data?.every((obj) => obj?.blpName !== 'N/A');
  };

  const enableScan = () => {
    // for (let obj of data) {
    //   if (obj?.blpName !== 'N/A' && obj?.scan_picked === false) return true;
    // }
    // return false;
    return true;
  };

  const enableConfirm = () => {
    for (let obj of data) {
      if (transfersData?.is_check_picking === true) {
        if (obj.check_move && obj.is_move_checked === false) return false;
      }
      if (transfersData?.picking_type !== 'loose_pieces')
        if (obj?.blpName !== 'N/A' && obj?.scan_picked === false) return false;
    }
    return true;
  };

  const startScanning = () => {
    setScanning((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    isCameraScanBool && !isCamScannerOpen ? setScanning(false) : '';
  });

  useEffect(() => {
    if (!enableScan() && scanning) {
      setScanning(false);
    }
  }, [data, scanning]);

  const startScanningDestination = () => {
    setScanDestinationLocationData((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      if (scanning) {
        scanPackageAPI(scanData, true);
      }
      if (scanDestinationLocationData) {
        handleDestinationLocationScan(scanData);
      }
      setScanData('');
      setScanDestinationLocationData(false);
    }
  }, [scanData, scanDestinationLocationData]);

  const scanPackageAPI = useDebounce((scanData, scanType) => {
    // if (scanType === true) successAudio.play();
    dispatch(setLoaderVisibility(true));
    warehousePackageScan({
      data: {
        package_name: scanData,
        id: transfersData?.id,
        model: transfersData?.model,
        scan_type: scanType ? 'add' : 'remove',
      },
    })
      .then((response) => {
        if (response.message.includes('not') || response.message.includes('more')) {
          if (scanType === true) failedAudio.play();
          popup(POPUP_TYPE.ERR, response.message);
        } else {
          if (scanType === true) successAudio.play();
          popup(
            POPUP_TYPE.SUCCESS,
            scanType ? response.message : `Package ${scanData} unscanned successfully`,
          );
        }
        dispatch(setLoaderVisibility(false));
        getTransfersList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        if (scanType === true) failedAudio.play();
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const updateQtyAPI = useDebounce((done_qty, move_line_id) => {
    dispatch(setLoaderVisibility(true));
    warehouseTransferQtyUpdate({
      data: {
        move_line_id: move_line_id,
        done_qty: parseFloat(done_qty),
      },
    })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        dispatch(setLoaderVisibility(false));
        getTransfersList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const getTransfersList = useDebounce(() => {
    warehouseTransfer({
      data: { picking_ids: transfersData?.id, page_number: pageNumber },
    })
      .then((response) => {
        setReload(false);
        dispatch(setLoaderVisibility(false));
        setTransfersData(response?.data?.records?.[0]);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleFinalizePick = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    finalizePick({
      data: { id: transfersData?.id, model: state?.model || transfersData?.model },
    })
      .then((response) => {
        setReload(true);
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handlePrintUpsLabel = async () => {
    dispatch(setLoaderVisibility(true));
    printUpsLabelDelivery({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.length > 0) {
          window.open(response?.data?.[0].split('?')[0], '_blank', 'noreferrer');
          getTransfersList();
        } else {
          popup(POPUP_TYPE.ERR, response.message);
        }
      })
      .catch((err) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, err.message);
        throw err;
      });
  };

  const handlePrintPackingList = async () => {
    dispatch(setLoaderVisibility(true));
    printPackingListDelivery({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.length > 0) {
          window.open(response?.data?.[0].split('?')[0], '_blank', 'noreferrer');
        } else {
          popup(POPUP_TYPE.ERR, response.message);
        }
      })
      .catch((err) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, err.message);
        throw err;
      });
  };

  const handleDestinationLocationScan = useDebounce((scanData) => {
    successAudio.play();
    dispatch(setLoaderVisibility(true));
    scanLocationkWT({
      data: { picking_id: transfersData?.id, barcode: scanData },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const validateOrder = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    warehouseValidatePicking({
      data: { picking_id: transfersData?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        if (!transfersData?.create_delivery_package) navigate(ROUTES.TRANSFERS);
        else getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleCheckPicking = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    warehouseCheckPicking({
      data: { picking_id: transfersData.id, model: state?.model || transfersData?.model },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        setReload(true);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleMarkPicking = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    markPick({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 300);

  const handleSignUpdate = useDebounce((signatureData) => {
    dispatch(setLoaderVisibility(true));
    warehouseSign({
      data: { picking_id: state?.id, signature: signatureData },
    })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        getTransfersList();
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 300);

  const handleCreateTransfer = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    createTransferWT({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length) {
          popup(POPUP_TYPE.SUCCESS, response.message);
          navigate(`${ROUTES.TRANSFER_DETAIL}/${response.data[0].name.replaceAll('/', '_')}`, {
            state: response.data[0],
          });
        } else popup(POPUP_TYPE.ERR, response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  function getUniquePackageIds(data) {
    const packageIds = new Set();
    data.forEach((item) => {
      if (item.package_id) {
        packageIds.add(item.package_id);
      }
    });
    return Array.from(packageIds);
  }
  const handlePrintLabel = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    printLabelDelivery({
      data: { sizes: ['4_6'], package_types: ['mix'], package_ids: getUniquePackageIds(data) },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          popup(POPUP_TYPE.SUCCESS, response.message);
          window.open(response.data[0].split('?')[0], '_blank', 'noreferrer');
        } else {
          popup(POPUP_TYPE.ERR, response.message);
        }
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 300);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getTransfersList();
  }, [pageNumber]);

  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getTransfersList();
    }
  }, [reload]);

  useEffect(() => {
    setData(innerTransferDetailedOperationCompiler(transfersData));
    setOpsData(innerTransferOperationsCompiler(transfersData));
  }, [transfersData]);
  const handleCreatePackage = (e) => {
    e.stopPropagation();
    navigate(ROUTES.CREATE_TRANSFER, { state: state });
  };

  useEffect(() => {
    if (
      (transfersData?.state === 'assigned' ||
        (transfersData?.state === 'in_progress' &&
          transfersData?.model === 'stock.picking.batch')) &&
      !hideScan() &&
      enableScan() &&
      transfersData?.picking_status !== 'checked' &&
      !transfersData?.finalize_picked &&
      activeTab !== 'upsWeightTab' &&
      getLocalStorageItem('allow_cp') === true
    ) {
      const handlePaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text').trim();
        if (pastedData) {
          scanPackageAPI(pastedData, true);
        }
      };
      window.addEventListener('paste', handlePaste);
      return () => {
        window.removeEventListener('paste', handlePaste);
      };
    }
  }, [scanPackageAPI]);

  const handleSign = () => {
    setShowPopup(3);
  };

  useEffect(() => {
    if (!state) navigate(ROUTES.TRANSFERS);
  }, [state]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.TRANSFER, route: ROUTES.TRANSFERS },
          { text: transfersData?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        {activeTab !== 'upsWeightTab' && (
          <div className="d-flex align-items-center justify-content-end">
            {scanDestinationLocationData && (
              <Scanner
                setScanData={setScanData}
                isCamScannerOpen={isCamScannerOpen}
                setIsCamScannerOpen={setIsCamScannerOpen}
              />
            )}
            {transfersData?.state === 'assigned' && transfersData?.finalize_picked && (
              <Button
                className={`white-btn d-flex align-items-center ${scanning ? 'disabled' : ''} justify-content-center px-4 mb-3 mx-2`}
                clickHandler={startScanningDestination}
                disabled={scanning}>
                {scanDestinationLocationData ? BUTTON.STOP_SCAN : BUTTON.SCAN_DESTINATION_LOCATION}
              </Button>
            )}
            {transfersData?.picking_status === 'claimed' && (
              <Button
                clickHandler={handleMarkPicking}
                className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                {BUTTON.MARK_PICKING}
              </Button>
            )}
            {transfersData?.sale_id &&
              (transfersData?.state === 'assigned' || transfersData?.state === 'in_progress') &&
              !transfersData?.finalize_picked &&
              (transfersData?.picking_status === 'checked' ||
                (transfersData?.is_check_picking === false &&
                  transfersData?.picking_status === 'picked')) && (
                <Button
                  clickHandler={handleFinalizePick}
                  className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                  {BUTTON.FINALIZE_PICK}
                </Button>
              )}
            {transfersData?.picking_type === false && (
              <div className="d-flex align-items-center justify-content-end">
                {(transfersData?.state === 'done' || transfersData?.state === 'assigned') && (
                  <Button
                    clickHandler={handlePrintPackingList}
                    className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                    {BUTTON.PRINT_PACKING_LIST}
                  </Button>
                )}
                {transfersData?.carrier_id?.delivery_type === 'ups' &&
                  transfersData?.generate_ups_label &&
                  transfersData?.carrier_tracking_ref === false && (
                    <Button
                      clickHandler={handlePrintUpsLabel}
                      className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                      {BUTTON.PRINT_UPS_LABEL}
                    </Button>
                  )}
              </div>
            )}
            {transfersData?.is_check_picking &&
              !transfersData?.is_picking_checked &&
              transfersData?.state === 'assigned' &&
              transfersData?.picking_status === 'picked' && (
                <Button
                  clickHandler={handleCheckPicking}
                  className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                  {BUTTON.STATUS_CHECK}
                </Button>
              )}
            {(transfersData?.picking_status === 'picked' ||
              transfersData?.picking_status === 'checked') && (
              <Button
                clickHandler={handleSign}
                className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                {BUTTON.SIGN_ORDER}
              </Button>
            )}
            {(transfersData?.state === 'assigned' ||
              (transfersData?.state === 'in_progress' &&
                transfersData?.model === 'stock.picking.batch')) && (
              <Button
                clickHandler={validateOrder}
                className={`blue-btn d-flex ${enableConfirm() ? '' : 'disabled'} ${transfersData?.finalize_picked ? '' : 'disabled'} align-items-center justify-content-center px-4 mx-2 mb-3`}
                disabled={!enableConfirm() || !transfersData?.finalize_picked}>
                {BUTTON.ConfirmTransferOrder}
              </Button>
            )}
            {transfersData?.state === 'done' &&
              hideCreateTransfer() &&
              transfersData?.create_delivery_package && (
                <Button
                  clickHandler={handlePrintLabel}
                  className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                  {BUTTON.PRINT_LABEL}
                </Button>
              )}
            {transfersData?.state === 'done' && (
              <Button
                className="white-btn d-flex align-items-center justify-content-center px-3 mb-3"
                clickHandler={handleCreateTransfer}>
                {BUTTON.CREATE_TRANSFER}
              </Button>
            )}
          </div>
        )}
        {activeTab !== 'upsWeightTab' && (
          <DetailBox colCount={2} colData={transfersData} dataCompiler={transferDetailBox} />
        )}

        {/* Tab Navigation */}
        <div className="d-flex align-items-center tab-nav bg-white">
          <div
            className={`text-center w-50 flex-fill ${activeTab === 'operations' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('operations')}>
            <h4>{HEADING.OPERATIONS}</h4>
          </div>
          <div
            className={`text-center w-50 flex-fill ${activeTab === 'detailOperation' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('detailOperation')}>
            <h4>{HEADING.DETAIL_OPERATIONS}</h4>
          </div>
          {transfersData?.generate_ups_label && (
            <div
              className={`text-center w-50 flex-fill ${activeTab === 'upsWeightTab' ? '' : 'active-nav-tab'}`}
              onClick={() => setActiveTab('upsWeightTab')}>
              <h4>{HEADING.UPS_WEIGHT_TAB}</h4>
            </div>
          )}
        </div>

        {activeTab === 'detailOperation' && (
          <InputWithSearch
            availableItem={data}
            selectedItem={selectedItems}
            setSelectedItem={setSelectedItems}
            clickedMove={clickedMove}
            setClickedMove={setClickedMove}
          />
        )}

        {/* Tab Content */}
        {activeTab === 'operations' && (
          <div className="tab-content detail-view-tab-content">
            {
              <div className="d-flex justify-content-end">
                {scanning && (
                  <Scanner
                    setScanData={setScanData}
                    isCamScannerOpen={isCamScannerOpen}
                    setIsCamScannerOpen={setIsCamScannerOpen}
                  />
                )}
                {(transfersData?.state === 'assigned' ||
                  (transfersData?.state === 'in_progress' &&
                    transfersData?.model === 'stock.picking.batch')) &&
                  !hideScan() &&
                  transfersData?.picking_status !== 'checked' &&
                  !transfersData?.finalize_picked && (
                    <Button
                      className={`white-btn ${enableScan() ? '' : 'disabled'} d-flex align-items-center justify-content-center px-4 mx-2`}
                      clickHandler={startScanning}
                      disabled={!enableScan()}>
                      {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
                    </Button>
                  )}
                {activeTab === 'detailOperation' &&
                  transfersData?.state === 'assigned' &&
                  !hideCreateTransfer() &&
                  transfersData?.create_delivery_package && (
                    <Button
                      clickHandler={(e) => handleCreatePackage(e)}
                      className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2`}>
                      {BUTTON.CREATE_PACKAGE}
                    </Button>
                  )}
              </div>
            }
            <div className="my-3">
              {isMultiStep() && (
                <Table
                  columns={filteredColumnsOperationsMultiPick}
                  initialData={transfersData}
                  colFilter={false}
                  compiler={{ use: true, tool: innerTransferOperationsCompiler }}
                  setReload={setReload}
                  text_highlight_total_picked={true}
                  opsData={opsData}
                  className="table-1 detail-view-table"
                  clickedMove={clickedMove}
                  setClickedMove={setClickedMove}
                  setActiveTab={setActiveTab}
                />
              )}
              {!isMultiStep() && (
                <Table
                  columns={filteredColumnsOperations}
                  initialData={transfersData}
                  colFilter={false}
                  compiler={{ use: true, tool: innerTransferOperationsCompiler }}
                  setReload={setReload}
                  text_highlight_total_picked={true}
                  opsData={opsData}
                  className="table-1 detail-view-table"
                  clickedMove={clickedMove}
                  setClickedMove={setClickedMove}
                  setActiveTab={setActiveTab}
                />
              )}
            </div>
          </div>
        )}
        {
          <div className="tab-content detail-view-tab-content">
            {activeTab === 'detailOperation' && (
              <div className="d-flex justify-content-end">
                {scanning && (
                  <Scanner
                    setScanData={setScanData}
                    isCamScannerOpen={isCamScannerOpen}
                    setIsCamScannerOpen={setIsCamScannerOpen}
                  />
                )}
                {(transfersData?.state === 'assigned' ||
                  (transfersData?.state === 'in_progress' &&
                    transfersData?.model === 'stock.picking.batch')) &&
                  !hideScan() &&
                  transfersData?.picking_status !== 'checked' &&
                  !transfersData?.finalize_picked && (
                    <Button
                      className={`white-btn ${enableScan() ? '' : 'disabled'} d-flex align-items-center justify-content-center px-4 mx-2`}
                      clickHandler={startScanning}
                      disabled={!enableScan()}>
                      {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
                    </Button>
                  )}
                {activeTab === 'detailOperation' &&
                  transfersData?.state === 'assigned' &&
                  !hideCreateTransfer() &&
                  transfersData?.create_delivery_package && (
                    <Button
                      clickHandler={(e) => handleCreatePackage(e)}
                      className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2`}>
                      {BUTTON.CREATE_PACKAGE}
                    </Button>
                  )}
              </div>
            )}
            {activeTab === 'detailOperation' && (
              <div className="my-3">
                <Table
                  columns={filteredColumns.filter((col) => !columnsToFilterOut.includes(col.name))}
                  initialData={clickedMove !== null || selectedItems?.length ? selectedItems : data}
                  to={null}
                  compiler={{ use: false }}
                  popup={showPopup}
                  className="table-1 detail-view-table"
                  setPopUp={setShowPopup}
                  setPopUpData={setPopUpData}
                  setReload={setReload}
                  isMultiStep={isMultiStep}
                />
                {showPopup === 1 && (
                  <PopUpBox openPopUpBox={showPopup}>
                    <QtyUpdate
                      apiCall={updateQtyAPI}
                      setOpenConfirmationBox={setShowPopup}
                      data={popUpData}
                    />
                  </PopUpBox>
                )}
                {showPopup === 2 && (
                  <PopUpBox openPopUpBox={showPopup}>
                    <RemoveScan
                      apiCall={scanPackageAPI}
                      pickingId={transfersData.id}
                      data={popUpData}
                      setOpenConfirmationBox={setShowPopup}
                    />
                  </PopUpBox>
                )}
              </div>
            )}
            {showPopup === 3 && (
              <PopUpBox openPopUpBox={showPopup}>
                <SignOrder
                  apiCall={handleSignUpdate}
                  setOpenConfirmationBox={setShowPopup}
                  picking_id={state?.id}
                />
              </PopUpBox>
            )}
          </div>
        }
        {activeTab === 'upsWeightTab' && (
          <>
            {transfersData?.state !== 'cancel' && (
              <div className="d-flex justify-content-end">
                {!showUpsWeightEntry && (
                  <Button
                    className="blue-btn d-flex align-items-center justify-content-center px-3 mb-3"
                    clickHandler={() => setShowUpsWeightEntry(true)}>
                    {BUTTON.UPDATE_SHIPPING_WEIGHTS}
                  </Button>
                )}
                {showUpsWeightEntry && (
                  <Button
                    className="blue-btn d-flex align-items-center justify-content-center px-3 mb-3"
                    clickHandler={() => setShowUpsWeightEntry(false)}>
                    {BUTTON.CANCEL}
                  </Button>
                )}
              </div>
            )}
            {showUpsWeightEntry && (
              <UPSWeightEntry
                pickingId={state?.id}
                data={transfersData?.packages}
                reload={reload}
                setReload={setReload}
                setActiveTab={setActiveTab}
                setShowUpsWeightEntry={setShowUpsWeightEntry}
              />
            )}
            {!showUpsWeightEntry && (
              <Table
                columns={UPS_WEIGHTS_COLUMN}
                initialData={transfersData?.packages || []}
                compiler={{ use: true, tool: upsWeightsCompiler }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export const SignOrder = ({ setOpenConfirmationBox, apiCall }) => {
  const sigCanvas = useRef();

  const closePopUp = () => {
    setOpenConfirmationBox(0);
  };

  const handleClear = () => {
    sigCanvas.current.clear();
  };

  const handleSave = () => {
    if (!sigCanvas.current || sigCanvas.current.isEmpty()) {
      popup(POPUP_TYPE.ERR, 'Please provide a signature');
      return;
    }
    const signatureData = sigCanvas.current.toDataURL('image/png'); // Ensure PNG format
    apiCall(signatureData);
    setOpenConfirmationBox(0);
  };

  return (
    <>
      <div className="d-flex flex-column m-1">
        <h4 className="text-center text-special mb-3">Sign Below</h4>
        <div className="w-100 modal-body py-0 d-flex flex-column gap-3 justify-content-center align-items-center">
          <div className="w-100 p-2">
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              canvasProps={{
                className: 'signature-pad curve-border w-100',
                style: {
                  width: '100%',
                  height: '180px',
                  border: '2px solid #ccc',
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                },
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4 modal-btn custom-button">
        <div className="d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleClear}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3 me-2">
            Clear
          </Button>
          <Button
            clickHandler={handleSave}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3 me-2">
            {BUTTON.SAVE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

const QtyUpdate = ({ setOpenConfirmationBox, apiCall, data }) => {
  const [doneQty, setDoneQty] = useState('');
  const qtyRef = useRef();
  const closePopUp = () => {
    setOpenConfirmationBox(0);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(0);
    apiCall(doneQty, data.move_line_id);
  };

  useEffect(() => {
    qtyRef.current.focus();
  }, []);
  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h4 className=" text-center text-special mb-3 "> Enter Qty. </h4>
        <div className="w-100 modal-body py-0 d-flex flex-column  gap-3 justify-content-center align-items-center">
          <div className="d-flex justify-content-between w-100 ">
            <label className="ms-3">Quantity</label>
            <input
              type="number"
              className="ps-2 curve-border me-4"
              placeholder={data.reserve_quantity}
              ref={qtyRef}
              onChange={(e) => {
                setDoneQty(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

const RemoveScan = ({ apiCall, setOpenConfirmationBox, data }) => {
  const closePopUp = () => {
    setOpenConfirmationBox(0);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(0);
    apiCall(data?.blpName, !data?.scan_picked);
  };
  return (
    <>
      <div className="d-flex m-1 ">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className=" text-center text-special mb-3 ">Un-Scan</h4>
          <p className="text-center text-special placeholder-color ">
            Are you sure to remove scan?
          </p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CONFIRM}
          </Button>{' '}
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

export const CheckUncheckTransfer = (data) => {
  const dispatch = useDispatch();
  const handleCheckUncheck = () => {
    dispatch(setLoaderVisibility(true));
    warehouseCheckUncheckMove({
      data: {
        move_id: [data.move_id],
        checked: data?.checked ? false : true,
      },
    })
      .then(() => {
        popup(POPUP_TYPE.SUCCESS, data?.checked ? 'Status Unchecked' : 'Status Checked');
        data.setReload(true);
        dispatch(setLoaderVisibility(false));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const handleCheck = () => {
    handleCheckUncheck();
  };
  return (
    <input
      type="checkbox"
      className="checkbox"
      checked={data?.checked}
      onClick={handleCheck}
      disabled={!data.check_move || data.state === 'confirmed'}
      readOnly={!data.check_move || data.state === 'confirmed'}
    />
  );
};
