import React, { useEffect, useState } from 'react';
import { DetailBox, InputWithSearch, Scanner, Table, TitleBar } from '../../common';
import {
  BUTTON,
  DELIVERY_OPERATIONS_COLUMN,
  HEADING,
  POPUP_MSG,
  POPUP_TYPE,
  ROUTES,
  TITLES,
  TRANSFER_DETAIL_PALLET_COLUMN,
} from '../../../constants';
import {
  innerTransferDetailedOperationPalletTransferCompiler,
  innerTransferOperationsCompiler,
  transferDetailBoxPallet,
} from '../../../utils/data_compiler/transferCompiler';
import { Button } from '../../shared';
import { ScanIcon } from '../../../assets/icons';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import {
  createTransferWT,
  warehouseCheckUncheck,
  warehouseTransfer,
  warehouseValidatePicking,
} from '../../../service';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { popup } from '../../../utils';
import scan_duplicate from '../../../assets/sounds/duplicate.mp3';
import scan_failed from '../../../assets/sounds/error.mp3';

export const TransfersDetailPalletTransfer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation(location?.state);
  const [reload, setReload] = useState(false);
  const [stateData] = useState(state);
  const [transfersData, setTransfersData] = useState(stateData);
  const [clickedMove, setClickedMove] = useState();
  const [data, setData] = useState(
    innerTransferDetailedOperationPalletTransferCompiler(transfersData?.move_ids),
  );
  const [activeTab, setActiveTab] = useState('operations');
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [showPopup, setShowPopup] = useState(0);
  const [pageNumber] = useState(1);
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  let successAudio = new Audio(scan_duplicate);
  let failedAudio = new Audio(scan_failed);

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  const [, setPopUpData] = useState('');
  const disabledStates = ['draft', 'waiting', 'done', 'cancel', 'confirmed'];
  let buttonsDisabled = disabledStates.includes(transfersData?.state);
  let filteredColumns;
  if (buttonsDisabled) {
    const columnsToFilterOut = ['Status'];
    if (transfersData?.state === 'draft' || transfersData?.state === 'waiting')
      columnsToFilterOut.push('Done Quantity');
    filteredColumns = TRANSFER_DETAIL_PALLET_COLUMN.filter(
      (col) => !columnsToFilterOut.includes(col.name),
    );
  } else {
    filteredColumns = TRANSFER_DETAIL_PALLET_COLUMN;
  }

  const enableConfirm = () => {
    for (let obj of data) {
      if (!obj?.checked) return false;
    }
    return true;
  };

  const startScanning = () => {
    setScanning((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    isCameraScanBool && !isCamScannerOpen ? setScanning(false) : '';
  });

  useEffect(() => {
    if (enableConfirm() && scanning) {
      setScanning(false);
    }
  }, [data, scanning]);

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      handleCheckUncheck(scanData);
      setScanData('');
      getTransfersList();
    }
  }, [scanData]);

  const handleCheckUncheck = (scanData) => {
    // successAudio.play();
    dispatch(setLoaderVisibility(true));
    const checkData = data.filter((item) => item.to === scanData);
    const apiData = data.filter((item) => item.to === scanData).map((item) => item.move_line_id);
    if (checkData.length) {
      warehouseCheckUncheck({
        data: {
          move_line_ids: apiData,
          status: data.checked ? false : true,
        },
      })
        .then(() => {
          successAudio.play();
          popup(POPUP_TYPE.SUCCESS, POPUP_MSG.STATUS_CHECKED_FOR_ALL);
          setReload(true);
          dispatch(setLoaderVisibility(false));
        })
        .catch((error) => {
          failedAudio.play();
          dispatch(setLoaderVisibility(false));
          popup(POPUP_TYPE.ERR, error.message);
        });
    } else {
      failedAudio.play();
      popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_LOCATION_SCANNED);
    }
  };
  const getTransfersList = useDebounce(() => {
    warehouseTransfer({
      data: { picking_ids: transfersData?.id, page_number: pageNumber },
    })
      .then((response) => {
        setReload(false);
        dispatch(setLoaderVisibility(false));
        setTransfersData(response?.data?.records?.[0]);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const validateOrder = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    warehouseValidatePicking({
      data: { picking_id: transfersData.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.TRANSFERS);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleCreateTransfer = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    createTransferWT({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length) {
          popup(POPUP_TYPE.SUCCESS, response.message);
          navigate(`${ROUTES.TRANSFER_DETAIL}/${response.data[0].name.replaceAll('/', '_')}`, {
            state: response.data[0],
          });
        } else popup(POPUP_TYPE.ERR, response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getTransfersList();
  }, [pageNumber]);

  useEffect(() => {
    if (reload) getTransfersList();
  }, [reload]);

  useEffect(() => {
    setData(innerTransferDetailedOperationPalletTransferCompiler(transfersData?.move_ids));
  }, [transfersData]);

  useEffect(() => {
    if (!state) navigate(ROUTES.TRANSFERS);
  }, [state]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.PALLET_TRANSFER, route: ROUTES.TRANSFERS },
          { text: transfersData?.name, route: ROUTES.TRANSFER_DETAIL_PALLET_TRANSFER },
        ]}
      />
      <div className="scroll-div">
        <div className="d-flex justify-content-end">
          {transfersData?.state === 'done' && (
            <Button
              className="white-btn d-flex align-items-center justify-content-center px-3 mb-3"
              clickHandler={handleCreateTransfer}>
              {BUTTON.CREATE_TRANSFER}
            </Button>
          )}
        </div>
        <DetailBox colCount={2} colData={transfersData} dataCompiler={transferDetailBoxPallet} />
        {/* Tab Navigation */}
        <div className="d-flex align-items-center tab-nav bg-white">
          <div
            className={`tabs text-center w-50 flex-fill ${activeTab === 'operations' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('operations')}>
            <h4>{HEADING.OPERATIONS}</h4>
          </div>
          <div
            className={`tabs text-center w-50 flex-fill ${activeTab === 'detailOperation' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('detailOperation')}>
            <h4>{HEADING.DETAIL_OPERATIONS}</h4>
          </div>
        </div>
        {activeTab === 'detailOperation' && (
          <InputWithSearch
            availableItem={data}
            selectedItem={selectedItems}
            setSelectedItem={setSelectedItems}
            clickedMove={clickedMove}
            setClickedMove={setClickedMove}
          />
        )}
        {/* Tab Content */}
        {activeTab === 'operations' && (
          <div className="tab-content custom-table-content h-100 detail-view-tab-content">
            <div className="my-3 h-100">
              <Table
                customClass={true}
                columns={DELIVERY_OPERATIONS_COLUMN}
                initialData={transfersData}
                PAGE_SIZE={5}
                colFilter={false}
                className="table-1 detail-view-table"
                compiler={{ use: true, tool: innerTransferOperationsCompiler }}
                clickedMove={clickedMove}
                setClickedMove={setClickedMove}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
        )}
        {activeTab === 'detailOperation' && (
          <div className="tab-content custom-table-content-1 h-100 detail-view-tab-content">
            {transfersData.state !== 'done' && (
              <div className="d-flex justify-content-end">
                {scanning && (
                  <Scanner
                    setScanData={setScanData}
                    isCamScannerOpen={isCamScannerOpen}
                    setIsCamScannerOpen={setIsCamScannerOpen}
                  />
                )}
                <Button
                  className={`white-btn ${!enableConfirm() ? '' : 'disabled'} d-flex align-items-center justify-content-center px-4 mx-2`}
                  clickHandler={startScanning}
                  disabled={enableConfirm()}>
                  {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_LOCATION} <ScanIcon />
                </Button>
                <Button
                  clickHandler={validateOrder}
                  className={`blue-btn d-flex ${enableConfirm() ? '' : 'disabled'} align-items-center justify-content-center px-4 mx-2`}
                  disabled={!enableConfirm()}>
                  {BUTTON.ConfirmTransferOrder}
                </Button>
              </div>
            )}
            <div className="my-3 h-100">
              <Table
                customClass={true}
                columns={filteredColumns}
                initialData={clickedMove !== null || selectedItems?.length ? selectedItems : data}
                to={null}
                compiler={{ use: false }}
                popup={showPopup}
                className="table-1 detail-view-table"
                setPopUp={setShowPopup}
                setPopUpData={setPopUpData}
                setReload={setReload}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export const CheckUncheckPalletTransfer = (data) => {
  return <input type="checkbox" className="checkbox" checked={data.checked} />;
};
