import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SearchBar, Table, TitleBar, DropDown } from '../../common';
import { useDebounce } from '../../../hooks';
import { getPelletList } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { popup, pelletListCompiler, getSortingArray } from '../../../utils';
import { Button } from '../../shared';
import { ROUTES, PALLETING_COLUMN, TITLES, POPUP_TYPE, BUTTON } from '../../../constants';

export const PelletList = () => {
  const [pelletData, setPelletData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort2, setSort2] = useState(PALLETING_COLUMN[6].name);
  const [sortingArray2, setSortingArray2] = useState([]);
  const dispatch = useDispatch();

  const getPelletingInfo = useDebounce((data = {}) => {
    getPelletList({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setPelletData(response.data);
          if (!sortingArray2.includes(sort2)) {
            setSortingArray2(
              getSortingArray({
                column: PALLETING_COLUMN[6],
                data: response.data,
                compiler: pelletListCompiler,
              }),
            );
          }
        } else {
          setPelletData(response.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);
  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        container_id: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getPelletingInfo(data);
    } else if (!searchTerm && !Array.isArray(pelletData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getPelletingInfo(data);
    }
  }, [search, searchTerm]);

  useEffect(() => {
    if (!searchTerm) {
      dispatch(setLoaderVisibility(true));
      getPelletingInfo();
    }
  }, [searchTerm]);

  const resetFilter = () => {
    setSort2(PALLETING_COLUMN[6].name);
    dispatch(setLoaderVisibility(true));
    getPelletingInfo();
  };
  useEffect(() => {
    let data = {};
    if (sortingArray2.length > 1) {
      data = {
        pallet_process_state: `${sortingArray2.includes(sort2) ? sort2.toLowerCase() : ''}`,
      };
      dispatch(setLoaderVisibility(true));
      getPelletingInfo(data);
    }
  }, [sort2]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.PELLETING, route: ROUTES.PELLETING }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray2} value={sort2} setValue={setSort2}></DropDown>
          {sortingArray2.includes(sort2) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(pelletData) ? (
        <Table
          columns={PALLETING_COLUMN}
          initialData={pelletData}
          PAGE_SIZE={10}
          colFilter={false}
          to={ROUTES.PELLETING}
          compiler={{ use: true, tool: pelletListCompiler }}
        />
      ) : (
        <div>
          <h4 className="px-1 text-center py-5">{pelletData}</h4>
        </div>
      )}
    </>
  );
};
