import * as React from 'react';
export const DetailsIcon = (props) => (
  <svg
    fill="#000000"
    width={22}
    height={22}
    viewBox="0 0 36 36"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}>
    <path
      d="M32,6H4A2,2,0,0,0,2,8V28a2,2,0,0,0,2,2H32a2,2,0,0,0,2-2V8A2,2,0,0,0,32,6Zm0,22H4V8H32Z"
      className="clr-i-outline clr-i-outline-path-1"
    />
    <path
      d="M9,14H27a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z"
      className="clr-i-outline clr-i-outline-path-2"
    />
    <path
      d="M9,18H27a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z"
      className="clr-i-outline clr-i-outline-path-3"
    />
    <path
      d="M9,22H19a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z"
      className="clr-i-outline clr-i-outline-path-4"
    />
    <rect x={0} y={0} width={36} height={36} fillOpacity={0} />
  </svg>
);
