import React, { useEffect, useState } from 'react';
import { DropDown, Scanner, SearchBar, Table, TitleBar } from '../../common';
import { ROUTES, TRANSFER_COLUMN, TITLES, POPUP_TYPE, BUTTON } from '../../../constants';
import { transferDataCompiler } from '../../../utils/data_compiler/transferCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { getSortingArray, popup } from '../../../utils';
import { createTransferWT, warehouseTransfer, warehouseTransferByScan } from '../../../service';
import { Button } from '../../shared';
import { ScanIcon } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';

export const Transfers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [transfersData, setTransfersData] = useState([]);
  const [initialTransfersData, setInitialTransfersData] = useState([]);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [clearEnabled, setClearEnabled] = useState(false);
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(TRANSFER_COLUMN[6].name);
  const [sortingArray1, setSortingArray1] = useState([]);

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        origin: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getTransfersList(data);
    } else if (!searchTerm && !Array.isArray(transfersData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getTransfersList(data);
    }
  }, [search, searchTerm]);

  const resetFilter = () => {
    setSort1(TRANSFER_COLUMN[6].name);
    dispatch(setLoaderVisibility(true));
    getTransfersList();
  };

  useEffect(() => {
    if (!searchTerm) {
      dispatch(setLoaderVisibility(true));
      getTransfersList();
    }
  }, [searchTerm]);

  useEffect(() => {
    let data = {};
    dispatch(setLoaderVisibility(true));
    if (sortingArray1.length > 1) {
      data = {
        state: `${sortingArray1.includes(sort1) ? (sort1.toLowerCase() === 'n/a' ? false : sort1.toLowerCase()) : ''}`,
      };
      getTransfersList(data);
    }
  }, [sort1]);

  const startScanning = () => {
    setScanning((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    isCameraScanBool && !isCamScannerOpen ? setScanning(false) : '';
  });

  // API Calling
  const getTransfersList = useDebounce((data = {}) => {
    warehouseTransfer({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setTransfersData(response.data);
          setInitialTransfersData(response.data);
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(
              getSortingArray({
                column: TRANSFER_COLUMN[6],
                data: response.data,
                compiler: transferDataCompiler,
              }),
            );
          }
        } else {
          setTransfersData(response.message);
          setInitialTransfersData(response.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const getTransfersByScan = useDebounce((scanData) => {
    warehouseTransferByScan({
      data: { package_name: [scanData] },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.message.includes('no')) popup(POPUP_TYPE.ERR, response.message);
        else popup(POPUP_TYPE.SUCCESS, response.message);
        setTransfersData(response.data);
        setScanning(false);
        setClearEnabled(true);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleCreateTransfer = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    createTransferWT({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length) {
          setScanning(false);
          popup(POPUP_TYPE.SUCCESS, response.message);
          navigate(`${ROUTES.TRANSFER_DETAIL}/${response.data[0].name.replaceAll('/', '_')}`, {
            state: response.data[0],
          });
        } else popup(POPUP_TYPE.ERR, response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getTransfersList();
  }, []);

  const handleClear = () => {
    setTransfersData(initialTransfersData);
    setScanData('');
    setClearEnabled(false);
  };

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      getTransfersByScan(scanData);
    }
  }, [scanData]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.TRANSFER, route: ROUTES.TRANSFERS }]} />
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-start gap-3 w-m-100">
          <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
          <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
            <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
            {sortingArray1.includes(sort1) && (
              <Button
                clickHandler={resetFilter}
                className="blue-btn d-flex align-items-center justify-content-center px-3">
                {BUTTON.CLEAR_FILTERS}
              </Button>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end ">
          {scanning && (
            <Scanner
              setScanData={setScanData}
              isCamScannerOpen={isCamScannerOpen}
              setIsCamScannerOpen={setIsCamScannerOpen}
            />
          )}
          {!clearEnabled && (
            <Button
              className={`white-btn d-flex align-items-center justify-content-center me-2 px-3 mb-3`}
              clickHandler={startScanning}>
              {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
            </Button>
          )}
        
        {clearEnabled && (
          <Button
            className="white-btn d-flex align-items-center justify-content-center px-3 mb-3 me-2"
            clickHandler={handleClear}>
            {BUTTON.CLEAR}
          </Button>
        )}
        <Button
          className="white-btn d-flex align-items-center justify-content-center px-3 mb-3"
          clickHandler={handleCreateTransfer}>
          {BUTTON.CREATE_TRANSFER}
        </Button>
        </div>
      </div>
      {Array.isArray(transfersData) ? (
        <Table
          columns={TRANSFER_COLUMN}
          initialData={transfersData}
          PAGE_SIZE={10}
          to={ROUTES.TRANSFER_DETAIL}
          compiler={{ use: true, tool: transferDataCompiler }}
        />
      ) : (
        <div>
          <h4 className="px-1 text-center py-5">{transfersData}</h4>
        </div>
      )}
    </>
  );
};
