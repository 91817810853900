import React, { useState, useEffect } from 'react';
import { DropDownArrow2, Close } from '../../assets/icons';

export const InputWithSearch = ({ availableItem = [], selectedItem = [], setSelectedItem }) => {
  const [dataToShow, setDataToShow] = useState(availableItem);
  const handleListClick = (item) => {
    setSelectedItem((pre) => (pre.includes(item) ? pre : [...pre, item]));
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    {
      const filteredData = availableItem
        ?.filter((listItem) => !selectedItem.includes(listItem))
        ?.filter((sku) => sku.toLowerCase().includes(searchValue));
      setDataToShow(filteredData);
    }
  };

  const handleRemove = (item) => {
    setSelectedItem((pre) => pre.filter((a) => a !== item));
  };
  
   useEffect(() => {
    setDataToShow(availableItem);
  }, [availableItem]);

  return (
    <div className="custom-dropdown-4 width-100 ">
      <div className="dropdown w-100">
        <button
          className="dropdown-toggle text-nowrap bg-white w-100 d-flex align-items-center justify-content-between"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          {selectedItem.length ? `${selectedItem.length} Item Selected` : 'Select Items'}
          <DropDownArrow2 />
        </button>
        <div className="dropdown-menu p-0 w-100" aria-labelledby="dropdownMenuButton1">
          {selectedItem.length
            ? selectedItem.map((item) => (
                <p key={item} className="d-inline border ms-2">
                  {`${item} `} <Close onClick={() => handleRemove(item)} />{' '}
                </p>
              ))
            : ''}
          <div className="w-100 mt-2 px-1">
            <input
              type="text"
              className="form-control"
              placeholder="Search.."
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <ul className="list-data-area w-100 m-0 p-0">
            {Array.isArray(dataToShow) && dataToShow?.length ? (
              dataToShow.map((Item) => (
                <li
                  key={Item}
                  onClick={() => {
                    handleListClick(Item);
                  }}>
                  <div
                    className={`dropdown-item t-wrap d-flex  align-items-center justify-content-start ${selectedItem.includes(Item) ? 'groupByList' : ''} `}>
                    {Item}
                  </div>
                </li>
              ))
            ) : (
              <li>
                <div className="dropdown-item t-wrap d-flex align-items-center justify-content-start">
                  {'No records found'}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
