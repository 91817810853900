import React from 'react';
import { EditIcon } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants';

export const ActionBoxMultiStepDeliveries = ({
  is_houston_one_step_delivery,
  finalize_picked,
  move_id,
  name,
  state,
}) => {
  const navigate = useNavigate();
  return (
    <div className="td-num">
      {is_houston_one_step_delivery && state !== 'done' && !finalize_picked ? (
        <EditIcon
          onClick={(e) => {
            e.stopPropagation();
            navigate(ROUTES.MULTI_STEP_PICKUP_CREATE_DELIVERIES, { state: { move_id, name } });
          }}
        />
      ) : (
        '-'
      )}
    </div>
  );
};
