import React, { useEffect, useState } from 'react';
import { DetailBox, InputWithSearch, PopUpBox, Scanner, Table, TitleBar } from '../../common';
import {
  BUTTON,
  HEADING,
  MIXED_TRANSFER_OPERATION_COLUMN,
  MIXED_TRANSFER_DETAIL_OPERATION_COLUMN,
  POPUP_TYPE,
  ROUTES,
  TITLES,
} from '../../../constants';
import {
  mixedTransferOperationsCompiler,
  mixedTransferDetailBox,
  mixedTransferDetailedOperationsCompiler,
} from '../../../utils/data_compiler/transferCompiler';
import { Button } from '../../shared';
import { ScanIcon } from '../../../assets/icons';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import {
  createTransferWT,
  mixedTransferpackageScan,
  mixedTransferValidate,
  warehouseTransfer,
} from '../../../service';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocalStorageItem, popup } from '../../../utils';
import scan_duplicate from '../../../assets/sounds/duplicate.mp3';
import scan_failed from '../../../assets/sounds/error.mp3';

export const MixedTransfer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation(location?.state);
  const [reload, setReload] = useState(false);
  const [transfersData, setTransfersData] = useState(state);
  const [operationsData, setOperatinsData] = useState(mixedTransferOperationsCompiler(state));
  const [detOpsData, setDetOpsData] = useState(
    mixedTransferDetailedOperationsCompiler(transfersData),
  );
  const [clickedMove, setClickedMove] = useState();
  const [selectedItemsOps, setSelectedItemsOps] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeTab, setActiveTab] = useState('operations');
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [showPopup, setShowPopup] = useState(0);
  const [popUpData, setPopUpData] = useState('');
  const [pageNumber] = useState(1);
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  let successAudio = new Audio(scan_duplicate);
  let failedAudio = new Audio(scan_failed);

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  const filteredOperationsColumn = MIXED_TRANSFER_OPERATION_COLUMN.filter(
    (item) =>
      !(
        (transfersData?.state === 'done' || transfersData?.state === 'cancel') &&
        item?.name === 'Action'
      ),
  );

  const disableScan = () => {
    return operationsData?.every((item) => item?.mix_scanned === true);
  };

  const enableValidate = () => {
    return operationsData?.some((item) => item?.mix_scanned === true);
  };

  const startScanning = () => {
    setScanning((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    isCameraScanBool && !isCamScannerOpen ? setScanning(false) : '';
  });

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      if (scanning) {
        scanPackageAPI(scanData, true);
      }
      setScanData('');
    }
  }, [scanData]);

  const scanPackageAPI = useDebounce((scanData, scanType) => {
    const matchingItem = mixedTransferOperationsCompiler(transfersData).find(
      (item) => item.blpName === scanData,
    );
    const packageId = matchingItem ? matchingItem.package_id : null;
    if (!packageId) {
      popup(POPUP_TYPE.ERR, `Package with BLP ${scanData} not found.`);
      return;
    }
    // if (scanType === true) successAudio.play();
    dispatch(setLoaderVisibility(true));
    mixedTransferpackageScan({
      data: {
        package_id: packageId,
        picking_id: transfersData?.id,
        scan_type: scanType ? 'add' : 'remove',
      },
    })
      .then((response) => {
        if (scanType === true) successAudio.play();
        popup(
          POPUP_TYPE.SUCCESS,
          scanType ? response.message : `Package ${scanData} unscanned successfully`,
        );
        dispatch(setLoaderVisibility(false));
        setOperatinsData(mixedTransferOperationsCompiler(response?.data?.[0]));
      })
      .catch((error) => {
        if (scanType === true) failedAudio.play();
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const getTransfersList = useDebounce(() => {
    warehouseTransfer({
      data: { picking_ids: transfersData?.id, page_number: pageNumber },
    })
      .then((response) => {
        setReload(false);
        dispatch(setLoaderVisibility(false));
        setTransfersData(response?.data?.records?.[0]);
        setOperatinsData(mixedTransferOperationsCompiler(response?.data?.records?.[0]));
        setDetOpsData(mixedTransferDetailedOperationsCompiler(response?.data?.records?.[0]));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handleCreateTransfer = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    createTransferWT({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length) {
          popup(POPUP_TYPE.SUCCESS, response.message);
          navigate(`${ROUTES.TRANSFER_DETAIL}/${response.data[0].name.replaceAll('/', '_')}`, {
            state: response.data[0],
          });
        } else popup(POPUP_TYPE.ERR, response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const validateMixTransfer = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    mixedTransferValidate({
      data: { picking_id: transfersData?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.TRANSFERS);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getTransfersList();
  }, [pageNumber]);

  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getTransfersList();
    }
  }, [reload]);

  useEffect(() => {
    if (
      transfersData?.state !== 'done' &&
      transfersData?.state !== 'cancel' &&
      !disableScan() &&
      getLocalStorageItem('allow_cp') === true
    ) {
      const handlePaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text').trim();
        if (pastedData) {
          scanPackageAPI(pastedData, true);
        }
      };
      window.addEventListener('paste', handlePaste);
      return () => {
        window.removeEventListener('paste', handlePaste);
      };
    }
  }, [scanPackageAPI]);

  useEffect(() => {
    if (!state) navigate(ROUTES.TRANSFERS);
  }, [state]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.MIXED_TRANSFER, route: ROUTES.TRANSFERS },
          { text: transfersData?.name, route: ROUTES.TRANSFER_DETAIL },
        ]}
      />
      <div className="scroll-div">
        {transfersData?.state !== 'done' && transfersData?.state !== 'cancel' && (
          <div className="d-flex align-items-center justify-content-end">
            <Button
              clickHandler={validateMixTransfer}
              className={`border-0 blue-btn ${!enableValidate() ? 'disabled' : ''} d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}
              disabled={!enableValidate()}>
              {BUTTON.VALIDATE}
            </Button>
          </div>
        )}
        <div className="d-flex justify-content-end">
          {transfersData?.state === 'done' && (
            <Button
              className="white-btn d-flex align-items-center justify-content-center px-3 mb-3"
              clickHandler={handleCreateTransfer}>
              {BUTTON.CREATE_TRANSFER}
            </Button>
          )}
        </div>
        <DetailBox colCount={2} colData={transfersData} dataCompiler={mixedTransferDetailBox} />
        {/* Tab Navigation */}
        <div className="d-flex align-items-center tab-nav bg-white">
          <div
            className={`text-center w-50 flex-fill ${activeTab === 'operations' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('operations')}>
            <h4>{HEADING.OPERATIONS}</h4>
          </div>
          <div
            className={`text-center w-50 flex-fill ${activeTab === 'detailOperation' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('detailOperation')}>
            <h4>{HEADING.DETAIL_OPERATIONS}</h4>
          </div>
        </div>
        {activeTab === 'operations' && (
          <InputWithSearch
            availableItem={operationsData}
            selectedItem={selectedItemsOps}
            setSelectedItem={setSelectedItemsOps}
            clickedMove={clickedMove}
            setClickedMove={setClickedMove}
            placeHolderText="Search by BLP"
          />
        )}
        {activeTab === 'detailOperation' && (
          <InputWithSearch
            availableItem={detOpsData}
            selectedItem={selectedItems}
            setSelectedItem={setSelectedItems}
            clickedMove={clickedMove}
            setClickedMove={setClickedMove}
          />
        )}
        {/* Tab Content */}
        {activeTab === 'operations' && (
          <div className="tab-content detail-view-tab-content">
            {transfersData?.state !== 'done' && transfersData?.state !== 'cancel' && (
              <div className="d-flex justify-content-end">
                {scanning && (
                  <Scanner
                    setScanData={setScanData}
                    isCamScannerOpen={isCamScannerOpen}
                    setIsCamScannerOpen={setIsCamScannerOpen}
                  />
                )}
                {
                  <Button
                    className={`white-btn d-flex ${disableScan() ? 'disabled' : ''} align-items-center justify-content-center px-4 mx-2`}
                    clickHandler={startScanning}
                    disabled={disableScan()}>
                    {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
                  </Button>
                }
              </div>
            )}
            <div className="my-3">
              <Table
                columns={filteredOperationsColumn}
                initialData={selectedItemsOps?.length ? selectedItemsOps : operationsData}
                setReload={setReload}
                popup={showPopup}
                setPopUp={setShowPopup}
                className="table-1 detail-view-table"
                setPopUpData={setPopUpData}
                clickedMove={clickedMove}
                setClickedMove={setClickedMove}
              />
            </div>
            {showPopup === 1 && (
              <PopUpBox openPopUpBox={showPopup}>
                <RemoveScan
                  apiCall={scanPackageAPI}
                  pickingId={transfersData.id}
                  data={popUpData}
                  setOpenConfirmationBox={setShowPopup}
                />
              </PopUpBox>
            )}
          </div>
        )}
        {activeTab === 'detailOperation' && (
          <div className="tab-content detail-view-tab-content">
            <div className="my-3">
              <Table
                columns={MIXED_TRANSFER_DETAIL_OPERATION_COLUMN}
                initialData={selectedItems?.length ? selectedItems : detOpsData}
                className="table-1 detail-view-table"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const RemoveScan = ({ apiCall, setOpenConfirmationBox, data }) => {
  const closePopUp = () => {
    setOpenConfirmationBox(0);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(0);
    apiCall(data?.blpName, !data?.mix_scanned);
  };
  return (
    <>
      <div className="d-flex m-1 ">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className=" text-center text-special mb-3 ">Un-Scan</h4>
          <p className="text-center text-special placeholder-color ">
            Are you sure to remove scan?
          </p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CONFIRM}
          </Button>{' '}
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
