import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TitleBar, Scanner, AlertBox } from '../../common';
import {
  TITLES,
  ROUTES,
  SHIPMENT_PACKAGE,
  SHIPMENT_STATUS,
  BUTTON,
  SHIPMENT_DETAIL_BOX,
  POPUP_TYPE,
  POPUP_MSG,
} from '../../../constants';
import { ScanIcon } from '../../../assets/icons';
import { ShipmentTile } from './ShipmentTile';
import { Button, StatusBox } from '../../shared';
import { useDebounce } from '../../../hooks';
import { warehouseApproveShipment, warehouseShipment } from '../../../service';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { transactionIndexDB, popup, idb } from '../../../utils';
import scan_success from '../../../assets/sounds/scan_success.mp3';
import scan_failed from '../../../assets/sounds/error.mp3';
import scan_duplicate from '../../../assets/sounds/duplicate.mp3';

export const ShipmentDetail = () => {
  const { state } = useLocation(location?.state);
  const [shipmentData, setShipmentData] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [isScanEnabled, setIsScanEnabled] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [tilesToDisplay, setTilesToDisplay] = useState([]);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [inputQuantity, setInputQuantity] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [tilesToReverse, setTilesToReverse] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let duplicateAudio = new Audio(scan_success);
  let failedAudio = new Audio(scan_failed);
  let successAudio = new Audio(scan_duplicate);

  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  const startScanning = () => {
    setScanning((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    isCameraScanBool && !isCamScannerOpen ? setScanning(false) : '';
  });

  const fetchShipmentList = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    warehouseShipment({ data: { picking_ids: state?.id, page_number: 1 } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.records?.length > 0) {
          setShipmentData(response?.data?.records?.[0]);
        } else {
          setShipmentData([]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 100);

  const packages = shipmentData?.package_level_ids?.map((pack) => ({
    id: pack?.package_id?.id,
    name: pack?.package_id?.name,
    sku: pack?.package_id?.quant_ids?.[0]?.product_id?.default_code,
    lot_name: pack?.package_id?.quant_ids?.[0]?.lot_id?.name,
    is_duplicate_scan: pack?.is_duplicate_scan,
  }));

  const handleInputChange = (e) => {
    const value = e.target.value;
    if ((value === '' || /^\d*$/.test(value)) && value <= packages?.length) {
      setInputQuantity(value);
    }
  };

  const handleClick = () => {
    const quantity = +inputQuantity;
    if (!isSaved) {
      if (quantity > 0) {
        setQuantity(quantity);
        setIsScanEnabled(true);
        setIsSubmitEnabled(false);
        setIsSaved(true);
      }
    } else {
      setQuantity(quantity);
      if (tilesToDisplay?.length === quantity) {
        setIsScanEnabled(false);
        setScanning(false);
        if (quantity === undefined || quantity === 0 || quantity === '') setIsSubmitEnabled(false);
        else setIsSubmitEnabled(true);
      } else {
        setIsScanEnabled(true);
        setIsSubmitEnabled(false);
      }
    }
  };

  const handleRemoveTile = (index) => {
    const remainingTiles = tilesToDisplay?.filter((_, i) => i !== index);
    const removedTile = tilesToDisplay?.[index];
    if (removedTile?.isDuplicateScan) {
      const newRemaining = remainingTiles?.map((tile) =>
        tile?.name === removedTile?.name ? { ...tile, tileClass: 'orange-shade' } : tile,
      );
      setTilesToDisplay(newRemaining.reverse());
    } else {
      const remainingRepetitives = remainingTiles?.filter((tile) => tile?.name === removedTile?.name);
      if (remainingRepetitives?.length === 1) {
        const newRemaining = remainingTiles?.map((tile) =>
          tile?.name === removedTile?.name && !tile?.isDuplicateScan
            ? { ...tile, tileClass: 'green-shade' }
            : tile,
        );
        setTilesToDisplay(newRemaining.reverse());
      } else {
        setTilesToDisplay(remainingTiles.reverse());
      }
    }
    if (remainingTiles?.length !== quantity) {
      setIsScanEnabled(true);
      setIsSubmitEnabled(false);
    }
  };

  const getOfflineData = ({ collection, item }) => {
    if (!item) {
      console.error('Invalid item key provided for IndexedDB query.');
      return;
    }
    const request = idb.open('shakaWear-db', 1);
    request.onerror = function (event) {
      console.error('IndexedDB error:', event);
    };
    request.onupgradeneeded = function () {
      const db = request?.result;
      if (!db.objectStoreNames.contains(collection)) {
        db.createObjectStore(collection, { keyPath: 'id' });
      }
    };
    request.onsuccess = function () {
      const db = request.result;
      if (!db.objectStoreNames.contains(collection)) {
        return;
      }
      const transaction = db.transaction(collection, 'readonly');
      const store = transaction.objectStore(collection);
      const query = store.get(item);
      query.onerror = function (event) {
        console.error('Error querying IndexedDB:', event);
      };
      query.onsuccess = function (event) {
        const result = event.target.result;
        if (result) {
          setTilesToDisplay(result?.data);
          setQuantity(result?.boxCount);
        } else if (shipmentData?.state === 'assigned') {
          transactionIndexDB({
            action: 'create',
            collection: 'ShipmentData',
            item: {
              id: state?.id,
              boxCount: quantity,
              shipment_id: state?.id,
              data: tilesToDisplay,
            },
          });
        }
      };
    };
  };

  useEffect(() => {
    getOfflineData({
      collection: 'ShipmentData',
      item: state?.id,
      navigateToMain: () => {
        navigate(ROUTES.SHIPMENT);
      },
    });
  }, []);

  const handleSubmit = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    const BLP_Data = tilesToDisplay?.map((tile, index) => [index + 1, tile?.id]);
    warehouseApproveShipment({
      data: { dispatch_id: state?.id, blp_list: BLP_Data },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        handleDelete();
        navigate(ROUTES.SHIPMENT);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    if (scanData) {
      let actualData;
      const scannedPackage = packages?.find((pack) => pack?.name === scanData);

      if (scannedPackage) {
        const isRepetitive = tilesToDisplay?.some((tile) => tile?.name === scanData);
        const isDuplicateScan = scannedPackage?.is_duplicate_scan;

        if (isRepetitive) {
          if (isDuplicateScan) {
            const existingDuplicate = tilesToDisplay?.find(
              (tile) => tile?.name === scanData && tile?.isDuplicateScan,
            );
            if (!existingDuplicate) {
              actualData = tilesToDisplay?.map((tile) =>
                tile?.name === scanData
                  ? { ...tile, tileClass: 'orange-shade', isDuplicateScan: true }
                  : tile,
              );
              setTilesToDisplay(actualData);
            }
          } else {
            actualData = tilesToDisplay?.map((tile) =>
              tile?.name === scanData ? { ...tile, tileClass: 'red-shade' } : tile,
            );
            setTilesToDisplay(actualData);
          }
          duplicateAudio.play();
          popup(POPUP_TYPE.ERR, POPUP_MSG.PACKAGE_ALREADY_SCANNED);
        } else {
          const newTile = {
            id: scannedPackage?.id,
            name: scanData,
            tileClass: isDuplicateScan ? 'orange-shade' : 'green-shade',
            isDuplicateScan: isDuplicateScan,
            lot_name: scannedPackage?.lot_name,
            sku: scannedPackage?.sku,
          };
          setTilesToDisplay((tilesToDisplay) => [
            ...(Array.isArray(tilesToDisplay) ? tilesToDisplay : []),
            newTile,
          ]);
          successAudio.play();
        }
        setScanData('');
      } else {
        failedAudio.play();
        popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_SCAN);
        setScanData('');
      }
    }
  }, [scanData, tilesToDisplay]);

  const handleDelete = () => {
    transactionIndexDB({
      action: 'delete',
      collection: 'ShipmentData',
      item: { id: state?.id },
    });
  };

  useEffect(() => {
    if (tilesToDisplay?.length >= 0 && state?.id) {
      setTilesToReverse(tilesToDisplay);
      transactionIndexDB({
        action: 'update',
        collection: 'ShipmentData',
        item: { id: state?.id, boxCount: quantity, shipment_id: state?.id, data: tilesToDisplay },
      });
    }
    if (tilesToDisplay?.length === quantity) {
      setIsScanEnabled(false);
      setScanning(false);
      if (quantity === 0 || quantity === '' || quantity === undefined) setIsSubmitEnabled(false);
      else setIsSubmitEnabled(true);
    } else {
      setIsScanEnabled(true);
      setIsSubmitEnabled(false);
    }
  }, [tilesToDisplay, quantity]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    fetchShipmentList();
  }, []);

  useEffect(() => {
    if (!state) {
      dispatch(setLoaderVisibility(true));
      navigate(ROUTES.SHIPMENT);
    }
  }, [state]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.SHIPMENT, route: ROUTES.SHIPMENT },
          { text: state?.container_id || state?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        <AlertBox heading={state?.state} />
        <div className="mb-3 new-content-box bg-white px-lg-5 p-4 py-lg-4">
          <div className="col-12">
            <div className="row">
              <div className="col-6 table-text">
                <div className="row">
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[0]}</div>
                  <div className="col-5 mb-4">{state?.container_id || 'N/A'}</div>
                  <div className="col-4 mb-4 t-wrap">{SHIPMENT_DETAIL_BOX[1]}</div>
                  <div className="col-5 mb-4">{state?.name}</div>
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[2]}</div>
                  <div className="col-5 mb-4">{state?.partner_id?.name}</div>
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[3]}</div>
                  <div className="col-5 mb-4">{packages?.length}</div>
                </div>
              </div>
              <div className="col-6 table-text">
                <div className="row">
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[4]}</div>
                  <div className="col-5 mb-4">{state?.scheduled_date.split(' ')[0]}</div>
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[5]}</div>
                  <div className="col-5 mb-4">{state?.date_deadline || 'N/A'}</div>
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[6]}</div>
                  <div className="col-5 mb-4">
                    {state?.state.charAt(0).toUpperCase() + state?.state.slice(1)}
                  </div>{' '}
                </div>
              </div>
            </div>
            {state?.state === 'assigned' && (
              <div className="col-xl-6 col-lg-7">
                <div className="d-flex align-items-center justify-content-start internal-input-bar-area">
                  <input
                    type="number"
                    className="internal-input-bar"
                    placeholder="Enter Box Quantity"
                    value={inputQuantity}
                    onChange={handleInputChange}
                  />
                  <Button
                    className={`blue-btn px-5 d-flex align-items-center justify-content-center ${inputQuantity === '' || parseInt(inputQuantity) < tilesToDisplay?.length || parseInt(inputQuantity) === quantity ? 'disabled' : ''}`}
                    clickHandler={handleClick}
                    disabled={
                      inputQuantity === '' ||
                      parseInt(inputQuantity) === quantity ||
                      parseInt(inputQuantity) < tilesToDisplay?.length
                    }>
                    {isSaved ? 'Update' : 'Save'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="bg-white p-3 package-top-info border-def-up">
          <div className="d-flex align-items-center justify-content-end mb-3 top-header-set">
            <div className="d-flex align-items-center mb-3">
              <h4 className="me-3 ">{SHIPMENT_PACKAGE[1]}</h4>
              <div className="number-count d-flex align-items-center justify-content-center">
                {tilesToDisplay?.length || 0}/{quantity || 0}
              </div>
            </div>
          </div>

          {state?.state === 'assigned' && (
            <div className="d-flex mb-3 justify-content-end">
              {scanning && (
                <Scanner
                  setScanData={setScanData}
                  isCamScannerOpen={isCamScannerOpen}
                  setIsCamScannerOpen={setIsCamScannerOpen}
                />
              )}
              <Button
                className={`white-btn d-flex align-items-center justify-content-center ${!isScanEnabled ? 'disabled' : ''} px-4 mx-2`}
                clickHandler={startScanning}
                disabled={!isScanEnabled}>
                {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
              </Button>
              <Button
                className={`blue-btn d-flex align-items-center justify-content-center ${!isSubmitEnabled || quantity === 0 ? 'disabled' : ''} px-4 mx-2`}
                clickHandler={handleSubmit}
                disabled={!isSubmitEnabled || quantity === 0}>
                {BUTTON.SUBMIT}
              </Button>
            </div>
          )}

          <StatusBox status={SHIPMENT_STATUS} />
          <div className="bg-white p-3 pt-0 package-titles-info">
            <div className="row custom-row">
              {tilesToReverse?.reverse()?.map((tile, index) => (
                <ShipmentTile key={index} tile={tile} onRemove={() => handleRemoveTile(index)} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
