import { capitalize } from '../misc';

export function pelletListCompiler(data) {
  let finalData = [];
  data.map((shipment) => {
    let shipmentData = {
      url: shipment.container_id || shipment.name.replaceAll('/', '_'),
      shipment_id: shipment.name,
      container_id: shipment.container_id || 'N/A',
      status: capitalize(shipment.pallet_process_state),
      packages: shipment.pallet_package_count,
      pellets: shipment.pallets_count,
      expected_date: shipment.scheduled_date,
      deadline: shipment.date_deadline,
      data: shipment,
    };
    finalData.push(shipmentData);
  });
  return finalData;
}

export function receptionDataCompiler(data) {
  let finalData = [];
  data.map((pack) => {
    let shipmentData = {
      id: data.id,
      product: pack.product_id.display_name,
      package_type: pack.package_type_id.name,
      total_packages: pack.total_packages,
      pallets_required: pack.pallets_required,
    };
    finalData.push(shipmentData);
  });
  return finalData;
}
