import React from 'react';
import { Search, Close } from '../../assets/icons';
import PropTypes from 'prop-types';
import { Input } from '../shared';

export const SearchBarStockLookup = ({ searchTerm, setSearchTerm, placeholder }) => {
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <div className="w-28 mb-3">
      <div className="container-search-bar w-100 d-flex align-items-center gap-2">
        <div className="bg-white position-relative d-flex align-items-center w-100 justify-content-start searchbar-area">
          <div className="search-btn2 position-absolute border-0">
            <Search />
          </div>
          <Input
            type="text"
            className="border-0 w-100 d-flex align-items-center justify-content-center search-placeholder"
            placeholder={placeholder || ''}
            value={searchTerm}
            onChange={handleInputChange}
          />
          {searchTerm && (
            <div onClick={clearSearch} className="search-btn2 border-0">
              <Close />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SearchBarStockLookup.propTypes = {
  placeholder: PropTypes.string,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};
