export function stockLookupDataCompiler(data) {
  let finalData = [];
  data.map((shipment) => {
    let shipmentData = {
      location_name: shipment?.location_id?.barcode || shipment.location_id?.name || 'N/A',
      product_name: shipment?.product_id?.name || 'N/A',
      number_of_packages: shipment?.num_packages || 0,
      number_of_pieces_available: shipment?.num_pieces_available
        ? parseFloat(shipment.num_pieces_available.toFixed(2))
        : 0,
      number_of_pieces_onhand: shipment?.num_pieces_on_hand
        ? parseFloat(shipment.num_pieces_on_hand.toFixed(2))
        : 0,
      data: shipment,
    };
    finalData.push(shipmentData);
  });
  return finalData;
}
