import React, { useState, useEffect } from 'react';

export const InputWithSearch = ({
  availableItem = [],
  setSelectedItem,
  clickedMove,
  setClickedMove,
  placeHolderText = '',
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    if (searchValue.length >= 0) {
      const filteredData = availableItem.filter(({ product, blpName, move_id }) => {
        const name = product?.toLowerCase() || '';
        const packageName = blpName?.toLowerCase() || '';
        const matchesSearch =
          name.includes(searchValue.toLowerCase()) ||
          packageName.includes(searchValue.toLowerCase());
        const matchesMoveId = clickedMove ? move_id === clickedMove : true;
        return matchesSearch && matchesMoveId;
      });
      setFilteredItems(filteredData);
      setSelectedItem(filteredData);
    }
  }, [searchValue, availableItem, setSelectedItem, clickedMove]);

  const handleClearSearch = () => {
    setSearchValue('');
    setFilteredItems([]);
    setSelectedItem([]);
    setClickedMove(null);
  };

  return (
    <div
      className="search-container px-1 mb-3"
      style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <input
        type="text"
        className="form-control"
        placeholder={placeHolderText || 'Search by Product or BLP'}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        style={{ minWidth: '300px', maxWidth: '600px' }}
      />
      {/* {clickedMove && (
        <span style={{ fontSize: '16px', fontWeight: 'bold', marginLeft: '10px' }}>
          Move ID: {clickedMove}
        </span>
      )} */}
      {(searchValue || clickedMove) && (
        <button
          className="delete-btn px-3"
          onClick={handleClearSearch}
          style={{ fontSize: '14px' }}>
          Clear Search and Filter
        </button>
      )}
      {searchValue && filteredItems.length === 0 && (
        <h5 style={{ fontSize: '16px', marginLeft: '10px', marginTop: '12px', color: 'red' }}>
          No records found
        </h5>
      )}
    </div>
  );
};
