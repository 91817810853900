import React from 'react';
import { ActionBox } from '../components/pages/transfers/ActionBox';
import { CheckUncheckTransfer } from '../components/pages/transfers/TransfersDetail';
import {
  AllQtyUpdateLADelivery,
  CheckUncheckDelivery,
} from '../components/pages/deliveries/DeliveryDetail';
import { CheckUncheckPalletTransfer } from '../components/pages/transfers/TrasfersDetailPalletTransfer';
import { ActionBoxWT } from '../components/pages/transfers/ActionBoxWT';
import {
  PhysicallyAvailableCheckUncheck,
  RemovePackage,
} from '../components/pages/transfers/WHTDetail';
import { EditIcon, DeleteRedIcon } from '../assets/icons';
import { ActionBoxDeliveryLA } from '../components/pages/deliveries/ActionBox';
import { ActionBoxMultiStep } from '../components/pages/transfers/ActionBoxMultiStep';
import { ActionBoxInnerMultiStep } from '../components/pages/transfers/ActionBoxInnerMultiStep';
import { ActionBoxMixedTransfer } from '../components/pages/transfers/ActionBoxMixedTransfer';
import { ActionBoxMultiStepDeliveries } from '../components/pages/deliveries/ActionBoxMultiStepDeliveries';
import { ActionBoxCheckDetails } from '../components/pages/transfers/ActionBoxCheckDetails';

// Warehouse
export const SHIPMENT_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Shipment No',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    sort: true,
    dataField: ['container'],
    valueType: 'String',
  },
  {
    name: 'Shipment Ref.',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['shipment_no'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Partner Name',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['partner_name'],
  },
  {
    name: 'Scheduled Date',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scheduled_date'],
    sort: true,
    valueType: 'Date',
  },
  {
    name: 'Package Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['package_count'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'Done' || status === 'Assigned') {
        statusClass = 'received';
      } else if (status === 'Waiting for Dispatch' || status === 'Cancel') {
        statusClass = 'waiting';
      } else if (status === 'In Transit') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const TRANSFER_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Source',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['source_document'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'PO Number',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['po_number'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Reference',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reference'],
  },
  {
    name: 'Backorder Of',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['backorder_id'],
  },
  {
    name: 'Customer',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['customer'],
  },
  {
    name: 'Shipping Method',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['shipping_method_name'],
  },
  {
    name: 'From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'Scheduled date',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scheduled_date'],
    sort: true,
    valueType: 'Date',
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'Done' || status === 'Confirmed') {
        statusClass = 'received';
      } else if (status === 'Waiting' || status === 'Draft' || status === 'Cancel') {
        statusClass = 'waiting';
      } else if (status === 'Assigned' || status === 'In Progress') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
  {
    name: 'Picking Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['picking_status'],
    customFormatter: ({ picking_status }) => {
      let statusClass;
      if (picking_status === 'Checked') {
        statusClass = 'dispatched';
      } else if (picking_status === 'Picked') {
        statusClass = 'waiting';
      } else if (picking_status === 'Finalize' || picking_status === 'Finalized') {
        statusClass = 'received';
      } else if (picking_status === 'Claimed') {
        statusClass = 'claimed';
      } else if (picking_status === 'Unclaimed') {
        statusClass = 'unclaimed';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{picking_status}</p>
        </div>
      );
    },
  },
];

export const TRANSFER_DETAIL_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Pick From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blpName'],
  },
  {
    name: 'Reserve Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reserve_quantity'],
  },
  {
    name: 'Done Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['done_quantity'],
  },
  // {
  //   name: 'Status',
  //   classNameTH: '',
  //   classNameTD: '',
  //   isCustom: true,
  //   dataField: ['move_line_id', 'checked', 'finalize_picked'],
  //   customFormatter: ({ checked, move_line_id, setReload, finalize_picked }) => {
  //     return finalize_picked ? (
  //       '-'
  //     ) : (
  //       <CheckUncheckTransfer checked={checked} move_line_id={move_line_id} setReload={setReload} />
  //     );
  //   },
  // },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: [
      'blpName',
      'scan_picked',
      'done_quantity',
      'reserve_quantity',
      'move_line_id',
      'create_delivery_package',
      'is_la_delivery',
      'isMultiStep',
      'finalize_picked',
      'picking_status',
    ],
    customFormatter: ({
      blpName,
      is_la_delivery,
      scan_picked,
      done_quantity,
      reserve_quantity,
      move_line_id,
      popup,
      setPopUp,
      setPopUpData,
      create_delivery_package,
      isMultiStep,
      finalize_picked,
      picking_status,
    }) => {
      return finalize_picked || create_delivery_package || picking_status === 'checked' ? (
        '-'
      ) : (
        <ActionBox
          finalize_picked={finalize_picked}
          is_la_delivery={is_la_delivery}
          blpName={blpName}
          create_delivery_package={create_delivery_package}
          move_line_id={move_line_id}
          scan_picked={scan_picked}
          done_quantity={done_quantity}
          reserve_quantity={reserve_quantity}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
          isMultiStep={isMultiStep}
        />
      );
    },
  },
];

export const TRANSFER_DETAIL_PALLET_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Pick From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blpName'],
  },
  {
    name: 'Reserve Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reserve_quantity'],
  },
  {
    name: 'Done Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['done_quantity'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['checked'],
    customFormatter: ({ checked, setReload }) => {
      return <CheckUncheckPalletTransfer checked={checked} setReload={setReload} />;
    },
  },
];

export const DELIVERY_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Source',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['source_document'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'PO Number',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['po_number'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Reference',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reference'],
  },
  {
    name: 'Backorder Of',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['backorder_id'],
  },
  {
    name: 'Customer',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['customer'],
  },
  {
    name: 'Shipping Method',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['shipping_method_name'],
  },
  {
    name: 'From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'Scheduled Date',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scheduled_date'],
    sort: true,
    valueType: 'Date',
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'Confirmed' || status === 'Done') {
        statusClass = 'received';
      } else if (status === 'Waiting' || status === 'Cancel' || status === 'Draft') {
        statusClass = 'waiting';
      } else if (status === 'Assigned' || status === 'In Progress') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
  {
    name: 'Picking Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['picking_status'],
    customFormatter: ({ picking_status }) => {
      let statusClass;
      if (picking_status === 'Checked') {
        statusClass = 'dispatched';
      } else if (picking_status === 'Picked') {
        statusClass = 'waiting';
      } else if (picking_status === 'Finalize' || picking_status === 'Finalized') {
        statusClass = 'received';
      } else if (picking_status === 'Claimed') {
        statusClass = 'claimed';
      } else if (picking_status === 'Unclaimed') {
        statusClass = 'unclaimed';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{picking_status}</p>
        </div>
      );
    },
  },
];

export const DELIVERY_OPERATIONS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Demand',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['demand'],
  },
  {
    name: 'Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['quantity'],
  },
  {
    name: 'Unit',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['unit'],
  },
  {
    name: 'Check Details',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['move_id', 'clickedMove', 'setClickedMove', 'setActiveTab'],
    customFormatter: ({ move_id, clickedMove, setClickedMove, setActiveTab }) => {
      return (
        <ActionBoxCheckDetails
          move_id={move_id}
          clickedMove={clickedMove}
          setClickedMove={setClickedMove}
          setActiveTab={setActiveTab}
        />
      );
    },
  },
];
export const TRANSFER_OPERATIONS_COLUMN_CHECK = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Demand',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['demand'],
  },
  {
    name: 'Estimated Boxes',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['estimated_boxes'],
  },
  // {
  //   name: 'Quantity',
  //   classNameTH: 'option-td',
  //   classNameTD: 'option-td',
  //   dataField: ['quantity'],
  // },
  {
    name: 'Total Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['total_picked_quantity'],
  },
  {
    name: 'Scan Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scan_picked_quantity'],
  },
  {
    name: 'Unit',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['unit'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: [
      'move_id',
      'finalize_picked',
      'checked',
      'check_move',
      'state',
      'is_check_picking',
      'picking_status',
    ],
    customFormatter: ({
      checked,
      move_id,
      finalize_picked,
      setReload,
      check_move,
      state,
      is_check_picking,
      picking_status,
    }) => {
      return state !== 'done' &&
        is_check_picking &&
        !finalize_picked &&
        (picking_status === 'checked' || picking_status === 'picked') ? (
        <CheckUncheckTransfer
          state={state}
          checked={checked}
          move_id={move_id}
          setReload={setReload}
          check_move={check_move}
        />
      ) : (
        '-'
      );
    },
  },
  {
    name: 'Check Details',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['move_id', 'clickedMove', 'setClickedMove', 'setActiveTab'],
    customFormatter: ({ move_id, clickedMove, setClickedMove, setActiveTab }) => {
      return (
        <ActionBoxCheckDetails
          move_id={move_id}
          clickedMove={clickedMove}
          setClickedMove={setClickedMove}
          setActiveTab={setActiveTab}
        />
      );
    },
  },
];
export const DELIVERY_OPERATIONS_COLUMN_CHECK = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Demand',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['demand'],
  },
  {
    name: 'Estimated Boxes',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['estimated_boxes'],
  },
  // {
  //   name: 'Quantity',
  //   classNameTH: 'option-td',
  //   classNameTD: 'option-td',
  //   dataField: ['quantity'],
  // },
  {
    name: 'Total Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['total_picked_quantity'],
  },
  {
    name: 'Scan Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scan_picked_quantity'],
  },
  {
    name: 'Unit',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['unit'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: [
      'move_id',
      'checked',
      'finalize_picked',
      'check_move',
      'state',
      'is_check_picking',
      'setReload',
      'picking_status',
    ],
    customFormatter: ({
      checked,
      move_id,
      setReload,
      finalize_picked,
      check_move,
      state,
      is_check_picking,
      picking_status,
    }) => {
      return state !== 'done' &&
        is_check_picking &&
        !finalize_picked &&
        (picking_status === 'picked' || picking_status === 'checked') ? (
        <CheckUncheckDelivery
          state={state}
          checked={checked}
          move_id={move_id}
          setReload={setReload}
          check_move={check_move}
        />
      ) : (
        '-'
      );
    },
  },
  {
    name: 'Check Details',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['move_id', 'clickedMove', 'setClickedMove', 'setActiveTab'],
    customFormatter: ({ move_id, clickedMove, setClickedMove, setActiveTab }) => {
      return (
        <ActionBoxCheckDetails
          move_id={move_id}
          clickedMove={clickedMove}
          setClickedMove={setClickedMove}
          setActiveTab={setActiveTab}
        />
      );
    },
  },
];

export const DELIVERY_LA_OPERATIONS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Demand',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['demand'],
  },
  {
    name: 'Estimated Boxes',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['estimated_boxes'],
  },
  // {
  //   name: 'Quantity',
  //   classNameTH: 'option-td',
  //   classNameTD: 'option-td',
  //   dataField: ['quantity'],
  // },
  {
    name: 'Total Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['total_picked_quantity'],
  },
  {
    name: 'Scanned Boxes',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scanned_boxes'],
  },
  {
    name: 'Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['picked_quantity'],
  },
  {
    name: 'Unit',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['unit'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: [
      'move_id',
      'checked',
      'check_move',
      'state',
      'is_start_picking_pressed',
      'is_check_picking',
      'finalize_picked',
      'picking_status',
    ],
    customFormatter: ({
      checked,
      move_id,
      setReload,
      check_move,
      state,
      finalize_picked,
      is_start_picking_pressed,
      is_check_picking,
      picking_status,
    }) => {
      return state !== 'done' &&
        is_start_picking_pressed &&
        is_check_picking &&
        !finalize_picked &&
        (picking_status === 'picked' || picking_status === 'checked') ? (
        <CheckUncheckDelivery
          checked={checked}
          move_id={move_id}
          setReload={setReload}
          check_move={check_move}
          is_start_picking_pressed={is_start_picking_pressed}
        />
      ) : (
        '-'
      );
    },
  },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: [
      'move_id',
      'picked_quantity',
      'is_start_picking_pressed',
      'state',
      'finalize_picked',
      'picking_status',
    ],
    customFormatter: ({
      move_id,
      state,
      picked_quantity,
      is_start_picking_pressed,
      popup,
      setPopUp,
      setPopUpData,
      finalize_picked,
      picking_status,
    }) => {
      return finalize_picked || picking_status === 'checked' ? (
        '-'
      ) : (
        <ActionBoxDeliveryLA
          finalize_picked={finalize_picked}
          is_start_picking_pressed={is_start_picking_pressed}
          move_id={move_id}
          state={state}
          picked_quantity={picked_quantity}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
  {
    name: 'Mass Pick',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: [
      'move_id',
      'picked_quantity',
      'demand',
      'total_picked_quantity',
      'is_start_picking_pressed',
      'state',
      'finalize_picked',
      'is_picking_checked',
      'is_check_picking',
    ],
    customFormatter: ({
      move_id,
      state,
      picked_quantity,
      demand,
      total_picked_quantity,
      is_start_picking_pressed,
      setReload,
      popup,
      setPopUp,
      setPopUpData,
      finalize_picked,
      is_picking_checked,
      is_check_picking,
    }) => {
      return finalize_picked || (is_check_picking && is_picking_checked) ? (
        '-'
      ) : (
        <AllQtyUpdateLADelivery
          finalize_picked={finalize_picked}
          is_start_picking_pressed={is_start_picking_pressed}
          move_id={move_id}
          demand={demand}
          total_picked_quantity={total_picked_quantity}
          state={state}
          setReload={setReload}
          picked_quantity={picked_quantity}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
  {
    name: 'Check Details',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['move_id', 'clickedMove', 'setClickedMove', 'setActiveTab'],
    customFormatter: ({ move_id, clickedMove, setClickedMove, setActiveTab }) => {
      return (
        <ActionBoxCheckDetails
          move_id={move_id}
          clickedMove={clickedMove}
          setClickedMove={setClickedMove}
          setActiveTab={setActiveTab}
        />
      );
    },
  },
];

export const DELIVERY_DETAIL_OPERATION_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blpName'],
  },
  {
    name: 'Reserve Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reserve_quantity'],
  },
  {
    name: 'Done Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['done_quantity'],
  },
  // {
  //   name: 'Status',
  //   classNameTH: '',
  //   classNameTD: '',
  //   isCustom: true,
  //   dataField: ['checked', 'move_line_id', 'finalize_picked'],
  //   customFormatter: ({ checked, move_line_id, setReload, finalize_picked }) => {
  //     return finalize_picked ? (
  //       '-'
  //     ) : (
  //       <CheckUncheckDelivery checked={checked} move_line_id={move_line_id} setReload={setReload} />
  //     );
  //   },
  // },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: [
      'blpName',
      'finalize_picked',
      'scan_picked',
      'done_quantity',
      'reserve_quantity',
      'move_line_id',
      'create_delivery_package',
      'is_la_delivery',
      'finalize_picked',
      'picking_status',
    ],
    customFormatter: ({
      blpName,
      move_line_id,
      create_delivery_package,
      scan_picked,
      done_quantity,
      reserve_quantity,
      is_la_delivery,
      popup,
      setPopUp,
      setPopUpData,
      finalize_picked,
      picking_status,
    }) => {
      return finalize_picked || picking_status === 'checked' ? (
        '-'
      ) : (
        <ActionBox
          finalize_picked={finalize_picked}
          is_la_delivery={is_la_delivery}
          move_line_id={move_line_id}
          create_delivery_package={create_delivery_package}
          blpName={blpName}
          scan_picked={scan_picked}
          done_quantity={done_quantity}
          reserve_quantity={reserve_quantity}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
];

export const PALLETING_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Shipment No.',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['container_id'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Shipment Ref.',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['shipment_id'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Pallets Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['pellets'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Package Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['packages'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Expected Date/Time',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['expected_date'],
    sort: true,
    valueType: 'Date',
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'Done') {
        statusClass = 'received';
      } else if (status === 'Assigned') {
        statusClass = 'waiting';
      } else if (status === 'Draft') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const RECEPTION_COLUMNS = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Package Type',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['package_type'],
  },
  {
    name: 'Package Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['total_packages'],
  },
  {
    name: 'Pallets Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['pallets_required'],
  },
];

export const SKU_COLUMN = [
  {
    name: 'SKU',
    classNameTH: 'd-flex justify-content-start px-5 ',
    classNameTD: 'd-flex justify-content-start align-items-center px-5 border ',
    dataField: ['name'],
  },
  {
    name: 'Quantity',
    classNameTH: 'option-th',
    classNameTD: 'option-td border',
    dataField: ['quantity'],
  },
  {
    name: 'Action',
    classNameTH: 'd-flex justify-content-center ',
    classNameTD: 'option-td border',
    isCustom: true,
    dataField: ['id', 'name', 'quantity'],
    customFormatter: ({ id, name, quantity, func }) => {
      return (
        <div className="d-flex justify-content-evenly h-100 align-items-center gap-3">
          <div
            onClick={() => {
              func({ data: { id, name, quantity }, action: 'edit' });
            }}
            className="w-50 h-100 m-0 d-flex align-items-center justify-content-center action-div-3">
            <EditIcon />
          </div>
          <div
            onClick={() => {
              func({ data: { id, name, quantity }, action: 'delete' });
            }}
            className="w-50 h-100 m-0 d-flex align-items-center justify-content-center action-div-3">
            <DeleteRedIcon />
          </div>
        </div>
      );
    },
  },
];

export const WT_OPERATIONS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blp'],
  },
  {
    name: 'Source Location',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['source_location'],
  },
  {
    name: 'Destination Location',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['destination_location'],
  },
  {
    name: 'Physically Available',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['physically_available', 'blp', 'package_id', 'stock_loaded', 'state'],
    customFormatter: ({
      physically_available,
      blp,
      package_id,
      setReload,
      stock_loaded,
      state,
    }) => {
      return (
        <PhysicallyAvailableCheckUncheck
          stock_loaded={stock_loaded}
          state={state}
          physically_available={physically_available}
          blp={blp}
          package_id={package_id}
          setReload={setReload}
        />
      );
    },
  },
  {
    name: 'Remove Package',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['blp', 'id', 'stock_loaded', 'state'],
    customFormatter: ({ blp, id, stock_loaded, setReload, state }) => {
      return (
        <RemovePackage
          blp={blp}
          state={state}
          stock_loaded={stock_loaded}
          id={id}
          setReload={setReload}
        />
      );
    },
  },
];

export const WT_OPERATIONS_COLUMN_DESTINATION_SET = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blp'],
  },
  {
    name: 'Source Location',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['source_location'],
  },
  {
    name: 'Destination Location',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['destination_location'],
  },
];

export const WT_TRANSFER_DETAIL_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Pick From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'On-hand Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['on_hand_quantity'],
  },
  {
    name: 'Physically Available Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['physically_available_quantity'],
  },
  // {
  //   name: 'Done Quantity',
  //   classNameTH: 'option-td',
  //   classNameTD: 'option-td',
  //   dataField: ['done_quantity'],
  // },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['physically_available_quantity', 'on_hand_quantity', 'move_line_id', 'state'],
    customFormatter: ({
      physically_available_quantity,
      on_hand_quantity,
      move_line_id,
      state,
      popup,
      setPopUp,
      setPopUpData,
    }) => {
      return (
        <ActionBoxWT
          move_line_id={move_line_id}
          state={state}
          physically_available_quantity={physically_available_quantity}
          on_hand_quantity={on_hand_quantity}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
];

export const WT_TRANSFER_DETAIL_COLUMN_DESTINATION_SET = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Pick From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'On-hand Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['on_hand_quantity'],
  },
];

export const TRANSFERS_MULTI_PICK_OPERATIONS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Demand',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['demand'],
  },
  {
    name: 'Estimated Boxes',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['estimated_boxes'],
  },
  // {
  //   name: 'Quantity',
  //   classNameTH: 'option-td',
  //   classNameTD: 'option-td',
  //   dataField: ['quantity'],
  // },
  {
    name: 'Total Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['total_picked_quantity'],
  },
  {
    name: 'Scan Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scan_picked_quantity'],
  },
  {
    name: 'Unit',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['unit'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: [
      'move_id',
      'finalize_picked',
      'checked',
      'check_move',
      'state',
      'is_check_picking',
      'picking_status',
    ],
    customFormatter: ({
      checked,
      move_id,
      finalize_picked,
      setReload,
      check_move,
      state,
      is_check_picking,
      picking_status,
    }) => {
      return state !== 'done' &&
        is_check_picking &&
        !finalize_picked &&
        (picking_status === 'checked' || picking_status === 'picked') ? (
        <CheckUncheckTransfer
          state={state}
          checked={checked}
          move_id={move_id}
          setReload={setReload}
          check_move={check_move}
        />
      ) : (
        '-'
      );
    },
  },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: [
      'multi_location_pick',
      'move_id',
      'name',
      'state',
      'finalize_picked',
      'picking_status',
    ],
    customFormatter: ({
      multi_location_pick,
      move_id,
      name,
      state,
      finalize_picked,
      picking_status,
    }) => {
      return finalize_picked || picking_status === 'checked' ? (
        '-'
      ) : (
        <ActionBoxMultiStep
          finalize_picked={finalize_picked}
          multi_location_pick={multi_location_pick}
          move_id={move_id}
          name={name}
          state={state}
        />
      );
    },
  },
  {
    name: 'Check Details',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['move_id', 'clickedMove', 'setClickedMove', 'setActiveTab'],
    customFormatter: ({ move_id, clickedMove, setClickedMove, setActiveTab }) => {
      return (
        <ActionBoxCheckDetails
          move_id={move_id}
          clickedMove={clickedMove}
          setClickedMove={setClickedMove}
          setActiveTab={setActiveTab}
        />
      );
    },
  },
];

export const MULTI_STEP_PICKUP_LINES_COLUMN = [
  {
    name: 'Location',
    classNameTH: 'd-flex justify-content-start',
    classNameTD: 'd-flex justify-content-start align-items-center px-5 ',
    dataField: ['location_name'],
  },
  {
    name: 'Quantity',
    classNameTH: 'option-th',
    classNameTD: 'option-td border',
    dataField: ['quantity'],
  },
  {
    name: 'Picked',
    classNameTH: 'option-th',
    classNameTD: 'option-td border',
    isCustom: true,
    dataField: ['scan_picked'],
    customFormatter: ({ scan_picked }) => {
      return <input type="checkbox" className="checkbox" checked={scan_picked} readOnly />;
    },
  },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['move_id', 'move_line_id', 'location_name', 'location_id', 'quantity'],
    customFormatter: ({
      move_id,
      move_line_id,
      quantity,
      location_name,
      location_id,
      popup,
      setPopUp,
      setPopUpData,
    }) => {
      return (
        <ActionBoxInnerMultiStep
          move_id={move_id}
          move_line_id={move_line_id}
          quantity={quantity}
          location_id={location_id}
          location_name={location_name}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
];

export const MIXED_TRANSFER_OPERATION_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blpName'],
  },
  {
    name: 'Pick From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['blpName', 'mix_scanned', 'picking_id', 'package_id'],
    customFormatter: ({
      blpName,
      mix_scanned,
      popup,
      setPopUp,
      setPopUpData,
      picking_id,
      package_id,
    }) => {
      return (
        <ActionBoxMixedTransfer
          blpName={blpName}
          mix_scanned={mix_scanned}
          picking_id={picking_id}
          package_id={package_id}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
];

export const MIXED_TRANSFER_DETAIL_OPERATION_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blpName'],
  },
  {
    name: 'Lot No',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['lot'],
  },
  {
    name: 'Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['quantity'],
  },
];

export const DELIVERIES_MULTI_PICK_HOUSTON_OPERATIONS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Demand',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['demand'],
  },
  {
    name: 'Estimated Boxes',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['estimated_boxes'],
  },
  // {
  //   name: 'Quantity',
  //   classNameTH: 'option-td',
  //   classNameTD: 'option-td',
  //   dataField: ['quantity'],
  // },
  {
    name: 'Total Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['total_picked_quantity'],
  },
  {
    name: 'Picked Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['picked_quantity'],
  },
  {
    name: 'Scanned Box Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scan_picked_quantity'],
  },
  {
    name: 'Unit',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['unit'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['move_id', 'finalize_picked', 'checked', 'check_move', 'state', 'is_check_picking'],
    customFormatter: ({
      checked,
      move_id,
      finalize_picked,
      setReload,
      check_move,
      state,
      is_check_picking,
    }) => {
      return state !== 'done' && is_check_picking && !finalize_picked ? (
        <CheckUncheckTransfer
          state={state}
          checked={checked}
          move_id={move_id}
          setReload={setReload}
          check_move={check_move}
        />
      ) : (
        '-'
      );
    },
  },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: [
      'is_houston_one_step_delivery',
      'move_id',
      'name',
      'state',
      'finalize_picked',
      'picking_status',
    ],
    customFormatter: ({
      is_houston_one_step_delivery,
      move_id,
      name,
      state,
      finalize_picked,
      picking_status,
    }) => {
      return finalize_picked || picking_status === 'checked' ? (
        '-'
      ) : (
        <ActionBoxMultiStepDeliveries
          finalize_picked={finalize_picked}
          is_houston_one_step_delivery={is_houston_one_step_delivery}
          move_id={move_id}
          name={name}
          state={state}
        />
      );
    },
  },
  {
    name: 'Check Details',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['move_id', 'clickedMove', 'setClickedMove', 'setActiveTab'],
    customFormatter: ({ move_id, clickedMove, setClickedMove, setActiveTab }) => {
      return (
        <ActionBoxCheckDetails
          move_id={move_id}
          clickedMove={clickedMove}
          setClickedMove={setClickedMove}
          setActiveTab={setActiveTab}
        />
      );
    },
  },
];

// StockLookupColumns.js
export const STOCK_LOOKUP_COLUMNS = [
  {
    name: '',
    classNameTH: 'location-name-td',
    classNameTD: 'location-name-td',
    dataField: [''],
  },
  {
    name: 'Location Name (Barcode)',
    classNameTH: 'location-name-td',
    classNameTD: 'location-name-td',
    dataField: ['location_name'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Product Name (SKU)',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product_name'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Number of Packages',
    classNameTH: 'packages-td',
    classNameTD: 'packages-td',
    dataField: ['number_of_packages'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Number of Pieces Available',
    classNameTH: 'pieces-td',
    classNameTD: 'pieces-td',
    dataField: ['number_of_pieces_available'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Number of Pieces On Hand',
    classNameTH: 'pieces-td',
    classNameTD: 'pieces-td',
    dataField: ['number_of_pieces_onhand'],
    sort: true,
    valueType: 'Number',
  },
];

// UPSWeightsColumns.js
export const UPS_WEIGHTS_COLUMN = [
  {
    name: '',
    classNameTH: 'location-name-td',
    classNameTD: 'location-name-td',
    dataField: [''],
  },
  {
    name: 'Package Name',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['package_name'],
    valueType: 'String',
  },
  {
    name: 'Package Weight',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['package_weight'],
    valueType: 'number',
  },
];
