import React from 'react';
import { ScanIcon } from '../../../assets/icons';

export const ActionBoxMixedTransfer = ({
  blpName,
  mix_scanned,
  setPopUp,
  setPopUpData,
  picking_id,
  package_id,
}) => {
  return (
    <div className="td-num">
      {mix_scanned ? (
        <ScanIcon
          onClick={(e) => {
            e.stopPropagation();
            setPopUp(1);
            setPopUpData({ blpName, mix_scanned, picking_id, package_id });
          }}
        />
      ) : (
        '-'
      )}
    </div>
  );
};
