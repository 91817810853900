import React from 'react';
import { capitalize } from '../misc';

export const deliveryDataCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let deliveryData = {
      url: obj.name.replaceAll('/', '_'),
      reference: obj.name || 'N/A',
      backorder_id: obj?.backorder_id || 'N/A',
      from: obj.location_id?.barcode || obj.location_id?.display_name || 'N/A',
      name: obj?.name,
      to: obj.location_dest_id?.barcode || obj.location_dest_id?.display_name || 'N/A',
      scheduled_date: obj.scheduled_date.split(' ')[0],
      source_document: obj.origin || 'N/A',
      po_number: obj?.po_number || 'N/A',
      status: capitalize(obj.state),
      customer: obj.sale_id?.partner_id?.name || 'N/A',
      shipping_method: capitalize(obj?.carrier_id?.delivery_type) || 'N/A',
      shipping_method_name: capitalize(obj?.carrier_id?.name) || 'N/A',
      picking_status: capitalize(obj?.picking_status) || 'N/A',
      is_houston_one_step_delivery: obj?.is_houston_one_step_delivery,
      model: obj?.model,
      data: obj,
    };
    finalData.push(deliveryData);
  });
  return finalData;
};

export function deliveryDetailBox(data) {
  let detailBoxData = [
    {
      name: 'Customer',
      col: 1,
      data: data?.sale_id?.partner_id?.name || data?.user_id?.name || 'N/A',
    },
    { name: 'Source Location', col: 1, data: data?.location_id?.display_name || 'N/A' },
    { name: 'Destination Location', col: 1, data: data?.location_dest_id?.display_name || 'N/A' },
    { name: 'Source', col: 1, data: data?.origin || 'N/A' },
    { name: 'PO Number', col: 1, data: data?.po_number || 'N/A' },
    { name: 'Backorder Of', col: 1, data: data?.backorder_id || 'N/A' },
    { name: 'Packing Preference', col: 1, data: data?.packing_preference || 'N/A' },
    { name: 'Delivery Note', col: 1, data: data?.sale_note || 'N/A' },
    { name: 'Packages', col: 1, data: data?.packages_count || 'N/A' },
    { name: 'Scanned Packages', col: 1, data: data?.total_scanned_packages || 'N/A' },
    { name: 'Scheduled Date', col: 2, data: data?.scheduled_date || 'N/A' },
    { name: 'Deadline', col: 2, data: data?.date_deadline || 'N/A' },
    { name: 'Status', col: 2, data: capitalize(data?.state) || 'N/A' },
    { name: 'Picking Status', col: 2, data: capitalize(data?.picking_status) || 'N/A' },
    { name: 'Shipping', col: 2, data: capitalize(data?.carrier_id?.name) || 'N/A' },
    {
      name: 'Shipping Detail',
      col: 2,
      data:
        data?.custom_shipping_label?.length > 100 ? (
          // eslint-disable-next-line react/react-in-jsx-scope
          <span title={data.custom_shipping_label}>
            {data.custom_shipping_label.slice(0, 100)}...
          </span>
        ) : (
          data?.custom_shipping_label || 'N/A'
        ),
    },
    {
      name: 'Signature',
      col: 2,
      data: data?.signature_image ? (
        <img
          className="border"
          src={`data:image/png;base64,${data.signature_image}`}
          alt="Signature"
          style={{ width: '150px', height: '80px', objectFit: 'contain' }}
        />
      ) : (
        <span>N/A</span>
      ),
    },
  ];
  return detailBoxData;
}
export const innerDeliveryOperationsCompiler = (data) => {
  let finalData = [];
  data?.move_ids?.map((obj) => {
    let deliveryOperationData = {
      estimated_boxes: obj?.estimated_boxes,
      total_picked_quantity: obj?.total_picked_qty,
      picked_quantity: obj?.picked_qty,
      is_houston_one_step_delivery: obj?.is_houston_one_step_delivery,
      scan_picked_quantity:
        data?.create_delivery_package === false && data?.picking_type === 'loose_pieces'
          ? 'N/A'
          : obj?.scan_picked_qty,
      move_id: obj?.id,
      is_check_picking: data?.is_check_picking,
      picking_status: data?.picking_status,
      name: data?.name,
      is_picking_checked: data?.is_picking_checked,
      check_move: obj?.check_move,
      checked: obj?.checked,
      finalize_picked: data?.finalize_picked,
      product: obj.product_id?.display_name,
      demand: obj.product_uom_qty,
      model: data?.model,
      quantity: obj.quantity,
      unit: obj.product_uom?.name,
      state: data?.state,
      is_start_picking_pressed: data?.is_start_picking_pressed,
    };
    finalData.push(deliveryOperationData);
  });
  return finalData;
};

export const innerDeliveryLAOperationsCompiler = (data) => {
  let finalData = [];
  data?.move_ids?.map((obj) => {
    let deliveryOperationData = {
      estimated_boxes: obj?.estimated_boxes,
      is_check_picking: data?.is_check_picking,
      is_picking_checked: data?.is_picking_checked,
      check_move: obj?.check_move,
      name: data?.name,
      picking_status: data?.picking_status,
      checked: obj?.checked,
      finalize_picked: data?.finalize_picked,
      product: obj.product_id?.display_name,
      state: data?.state,
      is_start_picking_pressed: data?.is_start_picking_pressed,
      demand: obj.product_uom_qty,
      quantity: obj.quantity,
      model: data?.model,
      unit: obj.product_uom?.name,
      total_picked_quantity: obj?.total_picked_qty,
      picked_quantity: obj?.picked_qty,
      scanned_boxes: obj?.scan_picked_qty,
      move_id: obj?.id,
    };
    finalData.push(deliveryOperationData);
  });
  return finalData;
};

export const innerDeliveryDetailedOperationCompiler = (data) => {
  let finalData = [];
  if (data?.move_ids?.length > 0) {
    data?.move_ids?.forEach((obj) => {
      if (obj.move_line_ids.length !== 0) {
        obj.move_line_ids.forEach((moveLine) => {
          let innerDeliveryData = {
            move_id: obj?.id,
            check_move: obj.check_move,
            is_move_checked: obj.checked,
            name: data?.name,
            move_line_id: moveLine.id,
            finalize_picked: data?.finalize_picked,
            is_la_delivery: data?.is_la_delivery,
            product: moveLine.product_id?.display_name,
            picking_status: data?.picking_status,
            create_delivery_package: data?.create_delivery_package,
            from: moveLine.location_id?.display_name,
            to: moveLine.location_dest_id?.display_name,
            blpName: moveLine.result_package_id?.name || 'N/A',
            checked: moveLine.checked,
            scan_picked: moveLine.scan_picked,
            reserve_quantity: moveLine.initial_reserve_qty,
            done_quantity: moveLine.done_qty,
            model: data?.model,
            data: moveLine,
          };
          finalData.push(innerDeliveryData);
        });
      }
    });
  }
  return finalData;
};
