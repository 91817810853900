import React from 'react';
import { EditIcon } from '../../../assets/icons';

export const ActionBoxDeliveryLA = ({ state,picked_quantity, move_id, is_start_picking_pressed, setPopUp, setPopUpData }) => {
  return (
    <div className="td-num">
      {is_start_picking_pressed && state!=='done' ? <EditIcon
        onClick={(e) => {
          e.stopPropagation();
          setPopUp(3);
          setPopUpData({ move_id, picked_quantity });
        }}
      />:'-'}
    </div>
  );
};
